<template>
  <div
      v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')"
      class="absences"
  >
    <!--    <h1>Absentismo</h1>-->
    <HeaderTitle title="Talento"/>
    <div class="d-flex flex-row justify-content-start">
      <img
          src="@/assets/icons/search-blue.svg"
          alt="">
      <div class="title-text ml-4">
        Absentismo
      </div>
    </div>
    <el-row class="mt-2">
      <el-card
          class="card-search card-abs w-100 wback"
          body-style="padding:0"
      >

        <div class="container-absences">
          <div class="row">
            <div class="col-md-8">
              <div class="form-item label-common">
                <label class="label-absence w-100">Nombre</label>
                <el-input
                    v-model="employeeName"
                    clearable
                    placeholder="Buscar por nombre"
                    @change="search()"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-item label-common">
                <label class="w-100">Departamento</label>
                <el-select
                    v-model="selectedDepartmentFilter.value"
                    placeholder="Todos"
                    clearable
                    filterable
                    @change="search()"
                >
                  <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-item date-picker label-common">
                    <label class="mb-2 w-100">Desde</label>
                    <el-date-picker
                        class="w-100"
                        v-model="selectedFromDate"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="YYYY-MM-DD"
                        :prefix-icon="customPrefix"
                        :picker-options="{firstDayOfWeek: 1}"
                        @change="search()"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-item date-picker label-common">
                    <label class="w-100 mb-2">Hasta</label>
                    <el-date-picker
                        v-model="selectedToDate"
                        class="w-100"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="YYYY-MM-DD"
                        :prefix-icon="customPrefix"
                        :picker-options="dueDatePickerOptions"
                        @change="search()"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 ">
              <div class="d-flex justify-content-center">
                <div class="col-md-6">
                  <button
                      type="submit"
                      class="btn btn_red btn-new"
                      @click="openNewAbsence"
                  >
                    Nuevo
                  </button>
                </div>
                <div class="col-md-6 ">
                  <button
                      class="btn btn_yellow btn_search"
                      @click="search()"
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </el-card>
    </el-row>
    <div class="table">
      <div v-if="absences && absences.length > 0">
        <table>
          <thead>
          <tr>
            <th>Nombre</th>
            <th>Fecha</th>
            <th>Inicio</th>
            <th>Fin</th>
            <th>Motivo</th>
            <th>Observaciones</th>
            <th>Justificantes</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="absence in filteredAbsences"
              :key="absence.id"
          >
            <td class="pointer">
              <div class="row-table-image-name-link">
                <EmployeeAvatar
                    :employee="absence.employee"
                />

                <div class="link-name">
                  <router-link
                      :to="{name: 'employee', params: { id: absence.employee.id }}"
                      style="text-decoration: none">
                    {{ absence.employee?.name }} {{ absence.employee?.lastName }}
                  </router-link>
                </div>
              </div>

            </td>
            <td
                class="pointer"
                @click="openNewAbsence(absence)"
            >
              {{ formatDate(absence.timestamp) }}
            </td>
            <td
                class="pointer"
                @click="openNewAbsence(absence)"
            >
              {{ formatDateFilter(absence.fromDate) }}
            </td>
            <td
                class="pointer"
                @click="openNewAbsence(absence)"
            >
              {{ formatDateFilter(absence.toDate) }}
            </td>
            <td
                class="pointer"
                @click="openNewAbsence(absence)"
            >
              {{ absence.absenceType.name }}
            </td>
            <td
                class="pointer"
                @click="openNewAbsence(absence)"
            >
              {{ absence.observations }}
            </td>
            <td
                class="pointer"
                @click="openNewAbsence(absence)"
            >
              {{ absence.absenceSupportingDocuments.length }}
            </td>
            <td>
              <!--                            <div class="buttons d-inline-flex" v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol)">-->
              <div class="buttons d-inline-flex">
                <shared-button-plus
                    color="#F9156F"
                    icon="times"
                    @click="deleteDialogShown = true; selectedAbsence = absence;"/>
                <shared-button-plus
                    icon="eye"
                    @onClick="openNewAbsence(absence)"/>
              </div>
            </td>
          </tr>
          <tr v-if="absences && absences.length > 0">
            <th>
              Total horas:
            </th>
            <td class="total-hours">
              {{ totalTimeOfAbsences }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado ausencias según los filtros especificados...</h5>
      </div>
      <SharedPagination
          v-if="absences && totalResults > 10"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          style="padding: 0px 0px 20px 0px;"
          @change="page = $event; getAbsences()"
      />
    </div>


    <SharedDialog
        v-if="deleteDialogShown && selectedAbsence"
        :title="'¿Desea eliminar permanentemente esta ausencia de ' + selectedAbsence.employee.name + ' ' + selectedAbsence.employee.lastName + '?'"
        :close-icon="false"
        @confirm="deleteAbsence(selectedAbsence)"
        @close="selectedAbsence = null;deleteDialogShown = false;"
        @decline="selectedAbsence = null;deleteDialogShown = false;"
    />

    <el-dialog
      v-model="showModalNewAbsence"
      @close="closeModal()"
      width="816"
      :center="true"
      :lock-scroll="true"
      :show-close="false"
      class="el-dialog"
    >
    <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">{{ absenceFormModel.id?"Editar Ausencia":"Nueva Ausencia"}}</span>

        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showModalNewAbsence=false"
        >
      </div>
      <div class="mt-2 my-body">
    <el-form
        ref="absenceForm"
        class="absence-form"
        :model="absenceFormModel"
        name="absenceForm"
        status-icon
        @submit.prevent
    >
      <!-- Nombre del empleado -->
      <div class="form-item">
        <div class="row">
          <div
              class="col-md-12 col-sm-12 pointer"
          >
            <div class="row">
              <div 
                :class="!absenceFormModel.id?'col-md-8':'col-md-12'"
              >
                <div class="col-md-3 d-inline-flex text-start">
                    <label
                    class="label-absence"
                    for="employeeName"
                  >*Nombre</label>
                </div>
                <div class="col-md-8 form-item">
                  <el-form-item prop="employeeName">
                    <el-select
                    v-model="absenceFormModel.employee"
                    filterable
                    placeholder="Nombre del empleado"
                    @change="changeEmployeeModal"
                    :value-key="'@id'"
                    >
                    <el-option
                    v-for="employee in employees"
                    :key="employee.id"
                    :label="employee.fullName"
                    :value="employee"
                    >
                  </el-option>
                </el-select>
                <p v-if="v.employeeName.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.employeeName.$errors[0].$message }}
                  </small>
                </p>
              </el-form-item>
            </div>
          </div>
              <div
              v-if="!absenceFormModel.id"
              class="col-md-4 col-sm-4 text-right"
              >
                <button
                    class="btn btn_red btn_new_modal"
                    style="padding-top: 4px"
                    @click="openModalEmployees"
                >
                  Seleccionar Empleado
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Fecha de inicio y de fin -->
      <div class="form-item">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-item date-picker">
                  <label class="w-100">*Desde:</label>
                  <el-date-picker
                      v-model="absenceFormModel.fromDate"
                      type="datetime"
                      placeholder="Selecciona fecha y hora"
                      format="DD/MM/YYYY HH:mm"
                      :clearable="false"
                      :prefix-icon="customPrefix"
                      :picker-options="{firstDayOfWeek: 1}"
                      :default-time="defaultTime[0]"
                  />
                  <p v-if="v.fromDate.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.fromDate.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-item date-picker">
                  <label class="w-100">*Hasta:</label>
                  <el-date-picker
                      v-model="absenceFormModel.toDate"
                      type="datetime"
                      placeholder="Selecciona fecha y hora"
                      format="DD/MM/YYYY HH:mm"
                      :picker-options="dueDatePickerOptions"
                      :prefix-icon="customPrefix"
                      :default-time="defaultTime[1]"
                  />
                  <p v-if="v.toDate.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.toDate.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tipo de absentismo -->
      <div class="form-item">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
            <div class="row tex-center mb-3">
              <div class="col-md-8 d-inline-flex">
                <label
                    class="label-absence"
                    for="absenceType"
                >*Tipo de absentismo</label>
              </div>
              <div class="col-md-8 form-item">
                  <el-select
                      v-model="absenceFormModel.absenceType"
                      placeholder="Tipo de Ausencia"
                      :value-key="'@id'"
                  >
                  <el-option
                      v-for="item in absenceTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                  />
                  </el-select>
                <p v-if="v.absenceType.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.absenceType.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
            
          </div>
          </div>
        </div>
      </div>
      <!-- Observaciones -->
      <div class="form-item">
        <div class="row text-center">
          <div class="col-md-12">
            <div class="row tex-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence label-observations">Observaciones</label>
              </div>
              <div class="col-md-12">
                <el-form-item
                    prop="observations"
                >
                  <textarea
                      v-model="absenceFormModel.observations"
                      class="font18 w-100 p-2"
                      style="height: 200px;"
                      placeholder="Observaciones del motivo de la baja"
                  />
                  <small v-if="v.observations.$error" class="invalid-feedback mb-0">
                    {{ v.observations.$errors[0].$message }}
                  </small>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <!-- Justificantes -->
      <div v-if="absenceFormModel.id != null">
        <div
            v-if="absenceFormModel && absenceFormModel.absenceSupportingDocuments && absenceFormModel.absenceSupportingDocuments.length > 0"
            class="row text-center"
        >
        <!-- Listado de Justificantes -->
          <div class="col-md-8">
            <div class="row tex-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence">Justificantes</label>
              </div>
              <div class="col-md-9">
                <div class="table-documents">
                  <table>
                    <tbody>
                    <tr
                        v-for="(document, index) in fileList"
                        :key="index"
                    >
                      <td
                          class="pointer"
                          @click="downloadDocument(document.id, document.name)"
                      >
                        {{ document.name }}
                      </td>
                      <td>
                        <div class="buttons d-inline-flex">
                          <div class="pointer">
                            <font-awesome-icon
                                class="btn_icon delete_btn"
                                icon="times"
                                title="Eliminar documento"
                                style="margin-left: 5px"
                                @click="deleteSupportingDocument(document, index)"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Insertar Justificantes -->
        <div class="row text-center mt-4">
          <div class="col-md-8">
            <div class="row text-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence">Añadir justificante</label>
              </div>
              <div
                  class="col-md-9 form-item text-left"
                  @click.stop="focusOnInput($event)"
              >
                <input
                    id="file"
                    ref="file"
                    type="file"
                    @change.stop="handleFileUpload"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Botones de guardado-->
      <div class="row mt-4">
        <div class="col-md-8">
          <div class="row">
            <div class="offset-md-3 col-md-9 d-flex justify-content-center">
              <div class="col-md-6 ">
                <routerLink
                    :to="{ name: 'absences'}"
                    class="btn btn-block btn_red"
                    style="height: 33.5px;line-height: 23px;"
                    @click="removeDocuments"
                >
                  Cancelar
                </routerLink>
              </div>
              <div class="col-md-6">
                <button
                    class="btn btn-block btn_yellow"
                    @click="saveAbsence()"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
    </el-dialog>

  </div>
</template>

<script setup>


// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedDialog from "@/components/shared/SharedDialog.vue";
import HeaderTitle from "@/components/layout/HeaderTitle";
import AbsenceService from '@/api/services/absence.service';
import AbsenceSupportingDocument from '@/api/services/absenceSupportingDocument.service';
import EmployeeService from '@/api/services/employee.service';
import ResourceService from '@/api/services/resource.service';
import Common from '@/api/services/common.service';
import {useStore} from 'vuex';
import moment from "moment";

import Notification from '@/utils/plugins/NotificationService'
import EmployeeAvatar from "@/components/shared/EmployeeAvatar.vue";
import {computed, h, onMounted, ref, shallowRef} from "vue";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import {ElNotification} from "element-plus";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
//import axios from "axios";
import dayjs from "dayjs";
import Api from "../../api/Api";
//import {useRouter} from "vue-router";

const store = useStore();
//const router = useRouter();

const absences = ref([]);
const filteredAbsences = ref([]);
// eslint-disable-next-line no-unused-vars
const absence = ref({employee: {name: '', lastName: ''}});
const absenceTypes = ref([]);
const page = ref(1);
const itemsPerPage = ref(10);
const totalResults = ref(0);
const params = ref({});
const employeeName = ref(null);
const employees = ref([]);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);
const deleteDialogShown = ref(false);
const selectedAbsence = ref(null);
const totalAbsences = ref(0);
const toDateDisabledDates = ref({to: null});
const selectedDepartmentFilter = ref({});
const departments = ref([]);
const showModalNewAbsence = ref(false);
const newFileList = ref([]);
const file = ref(null);
const fileList = ref([]);
const baseUrl = ref(process.env.VUE_APP_BASE_URI);

const absenceFormModel = ref({
  id: null,
  employee: null,
  employeeName: '',
  fromDate: null,
  toDate: null,
  absenceType: null,
  observations: null,
  absenceSupportingDocuments: []
});
const defaultTime = ref([
  // fecha actual desde las 00:00
  new Date(new Date().setHours(0, 0, 0, 0)),
  // fecha actual hasta las 23:59
  new Date(new Date().setHours(23, 59, 59, 0))
]);

const rules = computed(() => {
  return {
    employeeName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    fromDate:  {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    toDate: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    absenceType: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    observations: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
  };
});

const v$ = useVuelidate(rules, absenceFormModel);
const v = v$.value;

const calculateDisabledDates = (time) => {
  if (selectedFromDate.value) {
    let yesterday = new Date(selectedFromDate.value);
    yesterday.setDate(yesterday.getDate() - 1);
    return time.getTime() < yesterday.getTime();
  } else {
    return false;
  }
}

const dueDatePickerOptions = ref({
  firstDayOfWeek: 1,
  disabledDate: calculateDisabledDates
});


onMounted(() => {
  if (ifIsInArray(user.value.roles, 'ROLE_ADMIN')) {
    getAbsences();
    getDepartments();
    getAbsenceTypes();
    getEmployees();
  }
})

const user = computed(() => {
  return store.state.user;
});
const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
const totalTimeOfAbsences = computed(() => {
  let totalTime = 0;
  absences.value.forEach(function (absence) {
    let a = moment(absence.toDate ? absence.toDate : moment.now()).valueOf();
    let b = moment(absence.fromDate).valueOf();
    totalTime += (a - b);
  })
  return ((moment.duration(totalTime).days() !== 0) ? (Math.floor(moment.duration(totalTime).asDays()) + 'D:') : '') + moment.duration(totalTime).hours() + 'H:' + moment.duration(totalTime).minutes() + 'M';
});
const search = () => {
  page.value = 1;
  getAbsences();
}

const getAbsences = () => {
  params.value.page = page.value;
  params.value.itemsPerPage = itemsPerPage.value;

  if (employeeName.value && employeeName.value.length > 0) {
    params.value.orSearch_fullName = employeeName.value
  } else {
    delete params.value.orSearch_fullName;
  }
  if (selectedFromDate.value) {
    params.value['fromDate[after]'] = moment(selectedFromDate.value).format('YYYY-MM-DD 00:00:00')
  }
  if (selectedToDate.value) {
    params.value['toDate[before]'] = moment(selectedToDate.value).format('YYYY-MM-DD 23:59:59')
  }
  if (selectedDepartmentFilter.value) {
    params.value['employee.job.department.id'] = selectedDepartmentFilter.value.value;
  } else {
    delete params.value['employee.job.department.id'];
  }
  store.dispatch('setLoading', true);
  AbsenceService.getAbsences(params.value)
      .then((res) => {
        absences.value = res.data['hydra:member'];
        filteredAbsences.value = absences.value;
        totalResults.value = res.data['hydra:totalItems'];
        totalAbsences.value = absences.value.length
        store.dispatch('setLoading', false);
      })
      .catch(() => {
        ElNotification({
          type: 'error',
          message: 'Error al obtener listado de absentismo.'
        });
        store.dispatch('setLoading', false);
      })

}

const getDepartments = (searchQuery = '') => {
  const params = (searchQuery.length > 0 ? {name: searchQuery} : {});
  store.dispatch('setLoading', true);
  ResourceService.getDepartments(params).then((res) => {
    departments.value = res.data['hydra:member'];
    store.dispatch('setLoading', false);
  }).catch(() => {
    ElNotification({
      type: 'error',
      message: 'Error al obtener listado de departamentos.'
    });
    store.dispatch('setLoading', false);
  })
}

const openNewAbsence = (absence) => {
  showModalNewAbsence.value = true;
  if (absence.id) {    
    absenceFormModel.value = {
            id: absence.id,
            employee: absence.employee,
            employeeName: absence.employee.name + ' ' + absence.employee.lastName,
            fromDate: absence.fromDate,
            toDate: absence.toDate,
            absenceType: absence.absenceType,
            observations: absence.observations,
            absenceSupportingDocuments: absence.absenceSupportingDocuments.map(doc => doc['@id'])
          }
  }
  fileList.value = absence.absenceSupportingDocuments;
  //router.push(`/absences/${absence.id ? absence.id : 'new'}`)
}
// eslint-disable-next-line no-unused-vars
const getAbsenceTypes = (searchQuery = '') => {
  const params = {query: {name: searchQuery}};
  ResourceService.getAbsenceTypes(params)
      .then((res) => {
        absenceTypes.value = res.data['hydra:member'];
      })
}
const deleteAbsence = (absence) => {
  store.dispatch('setLoading', true);
  AbsenceService.deleteAbsence(absence.id)
      .then(() => {
        ElNotification({
          type: 'success',
          message: 'Ausencia eliminada correctamente'
        });
        store.dispatch('setLoading', false);
        selectedAbsence.value = null;
        deleteDialogShown.value = false;
        getAbsences();
      })
      .catch(() => {
        Notification.addNotification('Error al intentar eliminar la ausencia seleccionado.', 'success', 6000);
        store.dispatch('setLoading', false);
        selectedAbsence.value = null;
        deleteDialogShown.value = false;
        getAbsences();
      })
}
const formatDate = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  }
  return null;
}

// eslint-disable-next-line no-unused-vars
const fromDateSelected = (selectedDate) => {
  if (selectedDate != null) {
    // Deshabilitamos como fecha de fín todos los días anteriores a la fecha de 'desde'
    toDateDisabledDates.value.to = new Date();
    toDateDisabledDates.value.to.setDate(selectedDate.getDate() - 1);
  } else {
    toDateDisabledDates.value.to = null;
  }
}

const ifIsInArray = (userRoles, role) => {
  return Common.ifIsInArray(userRoles, role);
}

const formatDateFilter = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  } else {
    return '';
  }

}
// eslint-disable-next-line no-unused-vars
const formatDateTime = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm');
  } else {
    return '';
  }

}
// eslint-disable-next-line no-unused-vars
const formatCompleteDate = (value) => {
  moment.locale('es');
  return moment(value).format('DD MMMM YYYY');
}



// Functions of Modal
const removeDocuments = () => {
  newFileList.value.forEach(doc => {
    AbsenceSupportingDocument.deleteAbsenceSupportingDocument(doc.id)
        .then(() => {
          Notification.addNotification('Justificante eliminado correctamente.', 'success', 6000);
        })
        .catch(() => {
          Notification.addNotification('Error al intentar eliminar la ausencia seleccionado.', 'success', 6000);

        })
  });
}

const saveAbsence = () => {
  v$.value.$reset()
  v$.value.$validate();
  

if (!v$.value.$invalid) {
   absenceFormModel.value.employee = absenceFormModel.value.employee['@id'];
   absenceFormModel.value.fromDate = dayjs.utc(absenceFormModel.value.fromDate).tz("Europe/Madrid").format();
   absenceFormModel.value.toDate = dayjs.utc(absenceFormModel.value.toDate).tz("Europe/Madrid").format();
    if (absenceFormModel.value.id != null) {
     updateAbsence(updateAbsenceFromData(absenceFormModel.value));
   } else {
     createAbsence(updateAbsenceFromData(absenceFormModel.value));
   }
   }else{
     ElNotification({
       type:'error',
       message: 'Error al crear el registro. Revise los campos marcados en rojo',
       duration: 6000
     })
   }

}

const getEmployees = (filters) => {
  params.value = {
    ...params.value,
    filter: filters,
    'order[name]': 'asc',
    'active': 1,
    page: 1,
    itemsPerPage: 999999
  };
  EmployeeService.getEmployees(params.value)
      .then((res) => {
        employees.value = res.data['hydra:member'];
        totalResults.value = res.data['hydra:totalItems'];
      })
}

const closeModal = () => {
  v$.value.$reset()
  absenceFormModel.value = {};
}

const handleFileUpload = () => {
  const fileTypes = ['pdf', 'zip', 'rar', 'jpg', 'jpeg', 'png', 'doc', 'docx'];
  const newFile = file.value.files[0];
  const extension = newFile.name.split('.').pop().toLowerCase(),
      isSuccess = fileTypes.indexOf(extension) > -1;
  if (newFile && newFile.size < 2621440) {
    if (isSuccess) {
      const reader = new FileReader();
      reader.readAsBinaryString(newFile);
      reader.onloadstart = () => {
        file.value = 'loadStart';
      };
      reader.onload = () => {
        file.value = newFile;
        saveDocument();
      };
      reader.onerror = () => {
        file.value = 'error';
      };
    } else {
      Notification.addNotification('Este formato no está permitido.', 'error', 6000)
      file.value = null;
    }
  } else {
    Notification.addNotification('Tamaño máximo permitido 2.5 MB.', 'error', 6000)
    file.value = null;
  }
}

const downloadDocument = (url, label) => {
  let type = 'application/' + label.split('.').pop();
  
  let contentUrl;
  AbsenceSupportingDocument.getAbsenceAbsenceSupportingDocument(url)
  .then(res =>{
    contentUrl = res.data.contentUrl
    Api().get(baseUrl.value + contentUrl, {
        responseType: 'blob',
          })
      .then(response => {
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(() => {
    Notification.addNotification('Error al descargar el justificante', 'error', 6000);
  })
  }).catch(() => {
    Notification.addNotification('Error al descargar el justificante', 'error', 6000);
  })


}

const saveDocument = async () => {
  store.state.loading = true;
  await AbsenceSupportingDocument.uploadDocumentFile(file.value)
      .then((res) => {
        let id = res.data['@id'];
        absenceFormModel.value.absenceSupportingDocuments.push(id);
        fileList.value.push(res.data);
        newFileList.value.push(res.data);
        Notification.addNotification('Documento cargado correctamente', 'success', 6000);
        store.state.loading = false;
      })
      .catch(() => {
        Notification.addNotification('Error al cargar el documento', 'error', 6000);
        store.state.loading = false;
      });
}
const focusOnInput = () => {
  setTimeout(() => {
    document.querySelector('#file').focus()
    file.value.focus()
  }, 1000)
}



const updateAbsence = (absence) => {
  store.state.loading = true;
  AbsenceService.updateAbsence(absence, absence.id)
      .then(() => {
        store.state.loading = false;
        ElNotification({
          type: 'success',
          message: 'Ausencia actualizada correctamente'
        });
        Notification.addNotification('Ausencia actualizada correctamente', 'success', 6000);
        showModalNewAbsence.value = false;
      })
      .catch(() => {
        ElNotification({
          type: 'error',
          message: 'Error al actualizar la ausencia'
        });
        store.state.loading = false;
      });
}
const createAbsence = (absence) => {
  store.state.loading = true;
  AbsenceService.createAbsence(absence)
      .then(() => {
        ElNotification({
          type: 'success',
          message: 'Ausencia creada correctamente'
        });
        store.state.loading = false;
        showModalNewAbsence.value = false;
      })
      .catch(() => {
        ElNotification({
          type: 'error',
          message: 'Error al crear la ausencia'
        });
        store.state.loading = false;
      });
}

const updateAbsenceFromData = (absence)=>{
  const { absenceType, ...rest } = absence;
  const updatedAbsence = {
    ...rest, // Any other field
    absenceType: absenceType?.['@id'],
  };
  return updatedAbsence
}

const changeEmployeeModal = (selected) => {
  absenceFormModel.value.employeeName = selected.fullName;
}

const deleteSupportingDocument = (document, index) => {
  store.state.loading = true;
  AbsenceSupportingDocument.deleteAbsenceSupportingDocument(document.id)
      .then(() => {
        Notification.addNotification('Justificante eliminado correctamente.', 'success', 6000);
        let value = fileList.value[index]['@id'];
        fileList.value.splice(index, 1);
        absenceFormModel.value.absenceSupportingDocuments = absenceFormModel.value.absenceSupportingDocuments.filter(e => e !== value);
        if (absenceFormModel.value.absenceSupportingDocuments.length === 0) {
          delete absenceFormModel.value.absenceSupportingDocuments;
        }
        store.state.loading = false;
      })
      .catch(() => {
        Notification.addNotification('Error al intentar eliminar la ausencia seleccionado.', 'success', 6000);
        store.state.loading = false;
      })
}

</script>

<style
    lang="scss"
    scoped>
.card-search {
  border-radius: 15px;
  max-height: 380px;
  background: white url("../../assets/images/silla.png") no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}

.absences {
  .label-absence {
    color: #434343;
    float: left;
    display: block;
    white-space: nowrap;

  }
}


.total-hours {
  border-radius: 10px;
}

th:first-child, td:first-child {
  width: 300px; /* Fijo el tamaño de la primera columna */
}

.btn_search {
  color: $bg-blue;
  text-transform: uppercase;
  font-weight: bolder;
  margin-left: 10px;
}

.btn_search,
.btn-new {
  height: 40px;
  margin-top: 30px;
}

.btn_new_modal{
  min-height: 40px;
  margin-top: 10%;
  margin-left: -2%;
}


.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body{
    margin-right: 5%;
  }
}
</style>
