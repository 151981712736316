<template>
  <div class="clocky-container">
    <a
      target="_blank"
      @click="!timeEntryId ? start() : (changedType ? toggleShowDialog() :stop())"
    >
      Clockify
      <img
        src="@/assets/icons/clockify-blue.svg"
        alt="clockify"
      >
      <img
        v-if="!timeEntryId"
        src="@/assets/icons/play-blue.svg"
        alt="Arrancar reloj"
      >
      <img
        v-else
        src="@/assets/icons/stop-red.svg"
        alt="Parar reloj"
      >
    </a>
    <SharedDialog
      v-if="showStartDialog"
      title="La entrada de tiempo en curso tiene una etiqueta diferente al actual
           que se le ha asignado al ticket según su tipo"
      :close-icon="true"
      confirm-text="Cambiar etiqueta y parar reloj"
      decline-text="Solo parar reloj"
      message="¿Deseas cambiar la etiqueta de la entrada de tiempo en curso a la del tipo actual del ticket?"
      @confirm="updateEntry()"
      @close="toggleShowDialog()"
      @decline="stop()"
    />
  </div>
</template>

<script>
import clockifyService from "@/api/services/clockify.service";
import ticketService from "@/api/services/ticket.service";
import notificationService from "@/utils/plugins/NotificationService";
import SharedDialog from "@/components/shared/SharedDialog.vue";
import {ElNotification} from "element-plus";


export default {
  name: "ClockifyButton",
  components: {SharedDialog},
  props: {
    link: {
      type: String,
      required: true
    },
    clockifyProjectId: {
      type: String,
      required: true
    },
    clockifyTagId: {
      type: String,
      required: true
    },
    ticket: {
      type: Object,
      required: true
    },
  },
  emits: ['updateTicket'],
  data() {
    return {
      timeEntryId: null,
      showStartDialog: false,
      currentEntry: null
    }
  },
  computed: {
    isAssignedUser() {
      return this.ticket.assignedEmployee && this.$store.getters.getUserLogged['@id'] === this.ticket.assignedEmployee;
    },
     changedType(){
       return this.currentEntry && !this.currentEntry.tagIds.includes(this.ticket.ticketType.clockifyTagId)
     },
    disabledStart() {
      let id = this.ticket.status.split("/")[3];
      return id != 3 || !this.isAssignedUser()
    },
  },
  async mounted() {
    try {
      if (this.ticket.timeEntryId) {
        await clockifyService.getTimeEntry(this.$store.state.user.clockify['api-key'], this.ticket.timeEntryId)
            .then((response) => {
              let entry = response.data
              if(!entry.timeInterval.end){
                this.timeEntryId = entry.id
                this.currentEntry = entry
              }else{
                this.timeEntryId = null
                this.icon = '@/assets/icons/stop-red.svg'
                this.alt = 'Parar reloj'
              }
            }).catch(() => {
              this.timeEntryId = null
              ticketService.updateTicket({timeEntryId: null}, this.ticket.id)
            })

      }else{
        await clockifyService.getTimeEntries(this.$store.state.user.clockify['api-key'], this.$store.state.user.clockify.id)
            .then((response) => {
              let entry =null
              let entries = response.data
              if(entries.length > 0){
                entry = entries[0]
                if (entry.description === this.ticket.tag + ' - ' + this.ticket.id + ' - '+ this.ticket.issue) {
                  this.timeEntryId = entry.id
                  this.currentEntry = entry
                  this.icon = '@/assets/icons/stop-red.svg'
                  this.alt = 'Parar reloj'
                  ticketService.updateTicket({timeEntryId: this.timeEntryId}, this.ticket.id)
                }else{
                  this.stop(entries[0].id)
                }
              }


            }).catch((error) => {
              console.log(error)
              notificationService.error(error)
            })
      }

    }catch (error) {
      console.log(error)
     // notificationService.error(error)
    }


  },
  methods: {
    toggleShowDialog(){
      this.showStartDialog = !this.showStartDialog
    },
    async start() {
      if(this.disabledStart){
        ElNotification({
          type:'error',
          message:'Debes tener el ticket asignado y en estado Trabajando para poder crear la entrada de tiempo.',
          duration:10000,
          showClose: true
        })
        return
      }
      await clockifyService.startTime(this.$store.state.user.clockify['api-key'],
          {
            "billable": true,
            "customFields": [],
            "description": '['+this.ticket.tag + '] - ' + this.ticket.id + ' - '+ this.ticket.issue,
            "projectId": this.clockifyProjectId, //"5dca96fd9fbfdb5c52836240"
            "start": new Date().toISOString(),
            "tagIds": [this.clockifyTagId, '6582afa73119622b93ba2eb8'], // Add the tag id of the ticket type 'Tickets'
          }).then((response) => {
        if(response && typeof response !== 'undefined' && response.status && response.status === 201 || response.status === 200){
          this.timeEntryId = response.data.id;
          this.currentEntry = response.data;
          ticketService.updateTicket({timeEntryId: this.timeEntryId}, this.ticket.id)
          this.$emit('updateTicket')
        }

      })
    },
    async updateEntry() {
      this.currentEntry.tagIds = [this.clockifyTagId, '6582afa73119622b93ba2eb8']
      await clockifyService.updateEntry(this.$store.state.user.clockify['api-key'], this.currentEntry.id,{tagIds:  this.currentEntry.tagIds})
          .then(() => {
            this.stop();
          })
    },
    async stop(id =null) {
      let entry = {}
      this.$store.dispatch('setLoading', true)
       await clockifyService.getTimeEntry(this.$store.state.user.clockify['api-key'], id ? id :this.timeEntryId)
          .then(async (response) => {
            entry = response.data
            entry.end = new Date().toISOString()
            entry.start = response.data.timeInterval.start

            await clockifyService.stopTime(this.$store.state.user.clockify['api-key'], id ? id : this.timeEntryId,
                entry)
                .then(async () => {
                  if (!id) {
                    this.timeEntryId = null
                    await ticketService.updateTicket({timeEntryId: null}, this.ticket.id)
                  }

                }).catch((error) => {
                  notificationService.error(error)
                })
          })


      this.showStartDialog = false
      this.$store.dispatch('setLoading', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .clocky-container {
    cursor: default;
    border-radius: 16px;
    background-color: white;
    width: 360px;
    height: 85px;
    color: #474CE6;
    font-family: Montserrat;
    font-size: 23px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  img {
    &:first-child {
      margin-left: 5px;
    }
    margin-right: 3px;
    width: 58px;
    height: 64px;
  }
</style>
