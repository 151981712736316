
export default {

    COLORS: {
        'Alta': '#F9156F',
        'Media': '#eb4e0a',
        'Baja': '#137d2f',
    },

    ICONS: {
        'Alta': 'fa-thermometer-full',
        'Media': 'fa-thermometer-half',
        'Baja': 'fa-thermometer-empty',
    },

    getColor: function(state) {
        return this.COLORS[state] ?? 'white';
    },

    getIcon: function(state) {
        return this.ICONS[state] ?? 'white';
    }
}
