<template>
  <div class="holidays-calendar">
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Calendario de festivos
      </div>
    </div>
    <el-card
        class="card-search w-100 wback"
        body-style="padding:0"
    >
      <div class="row mx-5">
    <div class="container-holidays-calendar">
      <form ref="newHolidayForm">
        <!-- Año y centro de trabajo -->
        <div class="form-item">
          <div class="row text-center">
            <div class="col-md-6">
              <div class="row tex-center">
                <div class="col-md-6 d-inline-flex align-items-center">
                  <div class="form-item">
                    <label class="label-year w-100">Año</label>
                    <el-select
                        filterable
                        clearable
                        v-model="selectedYear"
                        :value-key="'id'"
                        placeholder="Selecciona el año"
                        @change="filterByYear"
                    >
                      <el-option
                          v-for="year in yearOptions"
                          :key="year.id"
                          :label="year.name"
                          :value="year"
                      />
                    </el-select>
                  </div>

                </div>

                <div class="col-md-6 d-inline-flex align-items-center">
                  <div class="form-item">
                  <label class="label-work-center w-100">Centro de trabajo</label>
                  <el-select
                      filterable
                      clearable
                      v-model="selectedWorkplace"
                      placeholder="Selecciona el centro de trabajo"
                      :value-key="'id'"
                      @change="filterByWorkplace"
                  >
                    <el-option
                      v-for="workplace in workplaceOptions"
                      :key="workplace.id"
                      :label="workplace.name"
                      :value="workplace"
                    />
                  </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
      </div>
      <div class="col-md-12 d-flex flex-column justify-content-end align-items-end pull-right">
        <button
            style="min-width:250px; max-width: 300px; color:white;"
            class="btn btn_darkpink btn-search"    
            @click="openModalNewPublicHoliday()"
        >
          Nuevo día festivo
        </button>
      </div>
    </el-card>
      <!-- Tabla -->
      <div class="table table-holidays-calendar">
        <div v-if="publicHolidays && publicHolidays.length > 0">
          <table>
            <thead>
              <tr>
                <th>Centro de trabajo</th>
                <th>Día</th>
                <th>Mes</th>
                <th>Festividad</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="publicHoliday in publicHolidays"
                :key="publicHoliday.id"
              >
                <td
                  class="pointer"
                  @click="openModalNewPublicHoliday(publicHoliday)"
                >
                  {{ publicHoliday.workplace.name }}
                </td>
                <td
                  class="pointer"
                  @click="openModalNewPublicHoliday(publicHoliday)"
                >
                  {{ formatDay(publicHoliday.date) }}
                </td>
                <td
                  class="pointer"
                  @click="openModalNewPublicHoliday(publicHoliday)"
                >
                  {{ formatMonthText(publicHoliday.date) }}
                </td>
                <td
                  class="pointer"
                  @click="openModalNewPublicHoliday(publicHoliday)"
                >
                  {{ publicHoliday.description }}
                </td>
                <td>
                  <!--                            <div class="buttons d-inline-flex" v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol)">-->
                  <div class="buttons d-inline-flex">
                    <div class="pointer">
                      <font-awesome-icon
                        class="btn_icon blue_btn"
                        icon="eye"
                        title="Editar"
                        style="margin-left: 5px"
                        @click="openModalNewPublicHoliday(publicHoliday);"
                      />
                    </div>
                    <div class="pointer">
                      <font-awesome-icon
                        class="btn_icon delete_btn"
                        icon="times"
                        title="Eliminar"
                        style="margin-left: 5px"
                        @click="deleteDialog(publicHoliday);"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <h5>No se han encontrado datos según los filtros especificados...</h5>
        </div>
        <SharedPagination
          v-if="publicHolidays && totalResults > itemsPerPage"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          @change="page = $event; getPublicHolidays()"
        />
      </div>

<!--      <div class="row">-->
<!--        <div class="col-md-12">-->
<!--          <div class="d-flex justify-content-end">-->
<!--            <div class="col-md-2">-->
<!--              <button-->
<!--                type="submit"-->
<!--                class="btn btn_blue new-holiday-button"-->
<!--                @click="openModalNewPublicHoliday()"-->
<!--              >-->
<!--                Nuevo día festivo-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->



    <!-- Dialogo de confirmación de eliminacion de una festividad -->
    <SharedDialog
      v-if="deleteDialogShown"
      :title="'¿Desea eliminar permanentemente la festividad?'"
      :message="deleteMsg"
      :close-icon="false"
      @confirm="deletePublicHoliday(selectedPublicHoliday)"
      @close="selectedPublicHoliday = null;deleteDialogShown = false;"
      @decline="selectedPublicHoliday = null;deleteDialogShown = false;"
    />


    <!-- Modal de crear un nuevo festivo -->
    <el-dialog
      v-model="showNewPublicHolidayModal"
      :before-close="closeModalNewPublicHoliday"
      :show-close="false"
        size="'medium'"
        width="750"
        :center="true"
        :lock-scroll="true"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
        >
        <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">{{ isUpdateHoliday ? 'Actualizar ' : 'Nuevo '}}Día Festivo</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="closeModalNewPublicHoliday"
        >
      </div>
      <div class="my-body">
        <el-form
          ref="newHolidayForm"
          class="newHolidayForm"
          :model="newHolidayFormModel"
          name="newHolidayForm"
          status-icon
          @submit.prevent
        >
          <div class="row item-modal">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-3 d-inline-flex align-items-center">
                  <label class="public-holiday-label-modal">Día festivo:</label>
                </div>
                <div class="col-sm-9">
                  <el-form-item prop="selectedHolidayDate">
                    <el-date-picker
                        type="date"
                        v-model="newHolidayFormModel.selectedHolidayDate"
                        :class="newHolidayFormRules.selectedHolidayDate.error ? 'error-validation' : ''"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12">
              <div class="row tex-center">
                <div class="col-sm-4 d-inline-flex align-items-center">
                  <label class="public-holiday-label-modal w-100">Año:</label>
                  <el-form-item prop="selectedYearModal">
                    <el-select
                        filterable
                        clearable
                        v-model="newHolidayFormModel.selectedYearModal"
                        placeholder="Todos"
                        @change="updateDatePicker"
                        :value-key="'id'"
                        :class="newHolidayFormRules.selectedYearModal.error ? 'error-validation' : ''"
                    >
                      <el-option
                          v-for="year in yearOptions"
                          :key="year.id"
                          :label="year.name"
                          :value="year"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12">
              <div class="row tex-center">
                <div class="col-sm-3 d-inline-flex align-items-center">
                  <label class="public-holiday-label-modal w-100">Festivo:</label>
                  <el-form-item prop="selectedTypePublicHoliday">
                    <el-select
                        filterable
                        clearable
                        v-model="newHolidayFormModel.selectedTypePublicHoliday"
                        placeholder="Selecciona el tipo de festividad"
                        :value-key="'id'"
                        :class="newHolidayFormRules.selectedTypePublicHoliday.error ? 'error-validation' : ''"
                    >
                      <el-option
                          v-for="typePublicHoliday in typesPublicHolidays"
                          :key="typePublicHoliday.id"
                          :label="typePublicHoliday.type"
                          :value="typePublicHoliday"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12">
              <label class="work-center-label w-100">Centro de trabajo</label>
              <el-form-item
                prop="checkedWorkplaces"
                class="modal-checkboxes-container"
                :class="newHolidayFormRules.checkedWorkplaces.error ? 'error-validation' : ''"
              >
                <el-checkbox-group v-model="checkedWorkplaces" size="small" >
                  <el-checkbox-button v-for="workplace in workplaceOptions" :key="workplace.id" :label="workplace" :value="workplace">{{
                    workplace.name
                    }}</el-checkbox-button>
                </el-checkbox-group>

              </el-form-item>
            </div>
          </div>

          <div class="row ">
            <div class="col-sm-12">
              <div class="row tex-center">
                <div class="col-sm-12 d-inline-flex align-items-center">
                  <label>Festividad de:</label>
                </div>
                <div class="col-sm-12">
                  <el-form-item prop="descriptionModal">
                    <el-input
                        type="textarea"
                        v-model="newHolidayFormModel.descriptionModal"
                        class="description-item"
                        :rows="3"
                        :error-validation="newHolidayFormRules.descriptionModal.error"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

          <div class="row item-modal offset-2">
            <div class="col-sm-5">
              <button
                class="btn btn-block btn_red"
                type="button"
                @click="closeModalNewPublicHoliday()"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-5">
              <button
                class="btn btn_blue"
                type="button"
                @click="requestNewPublicHoliday()"
              >
                Aceptar
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>

import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedDialog from "@/components/shared/SharedDialog.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import ResourceService from '@/api/services/resource.service';
import CommonService from '@/api/services/common.service';
import  {getYear}  from '@/views/production/mixin/common'
import PublicHolidayService from '@/api/services/publicHoliday.service';
import Notification from '@/utils/plugins/NotificationService'

import moment from "moment";
import {onMounted, ref, computed} from "vue";
import {useStore} from "vuex";
import dayjs from "dayjs";

const checkedWorkplaces = computed({
  get() {
    return workplaceOptions.value.filter(workplace =>
        newHolidayFormModel.value.checkedWorkplaces.some(
            checkedWorkplace => checkedWorkplace.id === workplace.id
        )
    );
  },
  set(newValue) {
    newHolidayFormModel.value.checkedWorkplaces = newValue;
  },
});
const newHolidayForm = ref(null)
const selectedHolidayDateRule = (rule, value, callback) => {
  if (!value) {
    newHolidayFormRules.value.selectedHolidayDate.error = true;
    callback(new Error("Debe especificar una fecha"));
  } else {
    newHolidayFormRules.value.selectedHolidayDate.error = false;
    callback();
  }
}
const selectedYearModalRule = (rule, value, callback) => {
  if (!value) {
    newHolidayFormRules.value.selectedYearModal.error = true;
    callback(new Error("Debe especificar un año"));
  } else {
    newHolidayFormRules.value.selectedYearModal.error = false;
    callback();
  }
}
const selectedTypePublicHolidayRule = (rule, value, callback) => {
  if (!value) {
    newHolidayFormRules.value.selectedTypePublicHoliday.error = true;
    callback(new Error("Debe especificar un tipo"));
  } else {
    newHolidayFormRules.value.selectedTypePublicHoliday.error = false;
    callback();
  }
}
const descriptionModalRule = (rule, value, callback) => {
  if (!value) {
    newHolidayFormRules.value.descriptionModal.error = true;
    callback(new Error("Debe especificar una descripción"));
  } else {
    newHolidayFormRules.value.descriptionModal.error = false;
    callback();
  }
}
const checkedWorkplacesRule = (rule, value, callback) => {
  if (!value || value.length <= 0) {
    newHolidayFormRules.value.checkedWorkplaces.error = true;
    callback(new Error("Por favor selecciona al menos un centro de trabajo"));
  } else {
    newHolidayFormRules.value.checkedWorkplaces.error = false;
    callback();
  }
}
const store = useStore();
const selectedYear =ref(new Date().getFullYear())
const yearOptions =ref([])
const serverTimestamp = ref(null)
const currentServerYear = ref(null)
// Selector de centro de trabajo
const selectedWorkplace = ref(null)
const workplaceOptions = ref(null)
// Calendario de festivos
// eslint-disable-next-line no-unused-vars
const publicHolidays = ref([])
// eslint-disable-next-line no-unused-vars
const totalResults = ref(0)
// eslint-disable-next-line no-unused-vars
const page = ref(1)
// eslint-disable-next-line no-unused-vars
const itemsPerPage = ref(10)
const params = ref({})
// Modal de nuevo día festivo
// eslint-disable-next-line no-unused-vars
const publicHolidayId = ref(null)
const isUpdateHoliday = ref(false);
const showNewPublicHolidayModal = ref(false)
const newHolidayFormModel = ref({
  publicHolidayId: null,
  selectedHolidayDate: null,
  selectedYearModal: null,
  selectedTypePublicHoliday: null,
  descriptionModal: null,
  checkedWorkplaces: []
})
const   newHolidayFormRules = ref({
  selectedHolidayDate: {
    required: true,
    validator: selectedHolidayDateRule,
    error: false,
    trigger: "blur"
  },
  selectedYearModal: {
    required: true,
    validator: selectedYearModalRule,
    error: false,
    trigger: "blur"
  },
  selectedTypePublicHoliday: {
    required: true,
    validator: selectedTypePublicHolidayRule,
    error: false,
    trigger: "blur"
  },
  descriptionModal: {
    required: true,
    validator: descriptionModalRule,
    error: false,
    trigger: "blur"
  },
  checkedWorkplaces: {
    required: true,
    validator: checkedWorkplacesRule,
    error: false,
    trigger: "blur"
  }
})
// Estado de las vacaciones
// eslint-disable-next-line no-unused-vars
const typesPublicHolidays = ref([
  {id: 0, type: 'Nacional'},
  {id: 1, type: 'Local'}
])
// Dialogo de confirmacion al eliminar un festivo
const deleteDialogShown = ref(false)
const selectedPublicHoliday = ref(null)
const deleteMsg = ref('')

onMounted(async () => {
  await initialize();
  params.value = store.state.params ?? {};
  // getPublicHolidays();
  await store.dispatch('setLoading', false);
})

const initialize = async () => {
 // await store.dispatch('setLoading', true);
  await CommonService.getServerTimestamp()
      .then(async (resTimestamp) => {
        serverTimestamp.value = resTimestamp.data.serverTimestamp;
        currentServerYear.value = getYear(serverTimestamp.value);

        // Rellenamos las opciones del desplegable de 'Año'
        yearOptions.value.push({
          id: parseInt(currentServerYear.value) + 1,
          name: (parseInt(currentServerYear.value) + 1).toString()
        });
        yearOptions.value.push({id: parseInt(currentServerYear.value), name: currentServerYear.value});
        yearOptions.value.push({
          id: parseInt(currentServerYear.value) - 1,
          name: (parseInt(currentServerYear.value) - 1).toString()
        });

        selectedYear.value = yearOptions.value[1];

        params.value['date[before]'] = moment(new Date(currentServerYear.value + "-12-31")).format('YYYY-MM-DD')
        params.value['date[after]'] = moment(new Date(currentServerYear.value + "-01-01")).format('YYYY-MM-DD')


        ResourceService.getWorkplaces({})
            .then((res) => {
              store.dispatch('setLoading', false);
              workplaceOptions.value = res.data['hydra:member'];
            })
            .catch(() => {
              store.dispatch('setLoading', false);
              Notification.addNotification('Error al obtener los centros de trabajo', 'error', 6000);
            })

        await getPublicHolidays();
        await store.dispatch('setLoading', false);

        /*
        EmployeeService.getEmployee(employeeID).then((res) => {
            employeeData = res.data;
            title = 'Vacaciones de ' + employeeData.name + ' ' + employeeData.lastName;

            getEmployeeHolidays(employeeID);
        })
        .catch(error => {
            Notification.addNotification('Error al obtener la información del empleado', 'error', 6000);
        });
        */
      })
      .catch(async () => {
        Notification.addNotification('Error al obtener el timestamp del servidor', 'error', 6000);
        await store.dispatch('setLoading', false);
      });
  await store.dispatch('setLoading', false);
}

// eslint-disable-next-line no-unused-vars
const filterByYear = () => {
  if (!selectedYear.value) {
    selectedYear.value = yearOptions.value[1];
  }
  params.value['date[before]'] = moment(new Date(selectedYear.value.name + "-12-31")).format('YYYY-MM-DD')
  params.value['date[after]'] = moment(new Date(selectedYear.value.name + "-01-01")).format('YYYY-MM-DD')

  page.value = 1;
  getPublicHolidays();
}

/* Filtro por centro de trabajo */
// eslint-disable-next-line no-unused-vars
const filterByWorkplace = () => {
  if (selectedWorkplace.value === null && typeof params.value['workplace.id'] !== "undefined") {
    delete params.value['workplace.id'];
  } else {
    params.value['workplace.id'] = selectedWorkplace.value?.id
  }
  page.value = 1;
  getPublicHolidays();
}

/* Obtiene los dias festivos */
const getPublicHolidays = async () => {
  params.value.page = page.value
  params.value.itemsPerPage = itemsPerPage.value
 // await store.dispatch('setLoading', true);
  await PublicHolidayService.getPublicHolidays(params.value)
      .then((res) => {
        store.dispatch('setLoading', false);
        publicHolidays.value = res.data['hydra:member'];
        totalResults.value = res.data['hydra:totalItems'];
      })
      .catch(() => {
        store.dispatch('setLoading', false);
        Notification.addNotification('Error al obtener el listado de dias festivos', 'error', 6000);
      })
}

// eslint-disable-next-line no-unused-vars
const openModalNewPublicHoliday = (publicHoliday = null) => {
  let today = moment(new Date()).format('YYYY-MM-DD')
  newHolidayFormModel.value.selectedYearModal = getYear(today);
  newHolidayFormModel.value.selectedHolidayDate = today;
  
  
  if (publicHoliday && publicHoliday.id) {
    newHolidayFormModel.value.publicHolidayId = publicHoliday.id;
    newHolidayFormModel.value.selectedHolidayDate = publicHoliday.date;
    isUpdateHoliday.value =true;    
    // TODO: Añadir el año al objeto de festividad año actual    
    newHolidayFormModel.value.selectedYearModal = getYear(publicHoliday.date);
    const findTypePublicHoliday = (typePublicHoliday)=> {
      return typePublicHoliday?.type?.toLowerCase() === publicHoliday?.type?.toLowerCase();
    }
    newHolidayFormModel.value.selectedTypePublicHoliday = typesPublicHolidays.value[typesPublicHolidays.value.findIndex(findTypePublicHoliday)];
    newHolidayFormModel.value.descriptionModal = publicHoliday.description;
    newHolidayFormModel.value.checkedWorkplaces.push(publicHoliday.workplace);
  }

  showNewPublicHolidayModal.value = true;
}

const closeModalNewPublicHoliday = () => {
  showNewPublicHolidayModal.value = false;
  isUpdateHoliday.value =false;
  resetVar()
}

const updateDatePicker = () => {
  if (!newHolidayForm.value.selectedYearModal)return
  console.log('updateDatePicker', newHolidayFormModel.value.selectedYearModal)
  // newHolidayFormModel.value.selectedYearModal = yearOptions.value[1];
  // actual date
  console.log(dayjs.utc(new Date).tz("Europe/Madrid").format())
  newHolidayFormModel.value.selectedHolidayDate = new Date(newHolidayFormModel.value.selectedYearModal.name + "-01-01")
  return new Date(newHolidayFormModel.value.selectedYearModal.name + "-01-01")
}

/* Creación y modificación de PublicHoliday */
// eslint-disable-next-line no-unused-vars
const requestNewPublicHoliday = () => {
  newHolidayForm.value.validate(valid => {
    if (valid) {
      const workplace = newHolidayFormModel.value.checkedWorkplaces;
      workplace.forEach(workplace => {
        let publicHoliday = {
          date: dayjs.utc(newHolidayFormModel.value.selectedHolidayDate).tz("Europe/Madrid").format(),
          description: newHolidayFormModel.value.descriptionModal,
          type: (newHolidayFormModel.value.selectedTypePublicHoliday.type).toLowerCase(),
          workplace: workplace['@id']
        }
        if (newHolidayFormModel.value.publicHolidayId) {
          publicHoliday.id = newHolidayFormModel.value.publicHolidayId;
          newHolidayFormModel.value.publicHolidayId = null;
        }

        if (publicHoliday.id) {
          updatePublicHoliday(publicHoliday);
        } else {
          createPublicHoliday(publicHoliday);
        }
      });
      resetVar();
      closeModalNewPublicHoliday();

    } else {
      return false;
    }
  })
}
/*const checkType = (selectedType) => {
  if (selectedType.id === 0) {    // Nacional
    workplaceOptions.value.forEach(workplace => {
      newHolidayFormModel.value.checkedWorkplaces.push(workplace);
    })
  } else {
    newHolidayFormModel.value.checkedWorkplaces.length = 0;
  }
}*/
/* Creacion de un nuevo registro publicHoliday */
const createPublicHoliday = async (publicHoliday) => {
  //await store.dispatch('setLoading', true);
  await PublicHolidayService.createPublicHoliday(publicHoliday)
      .then(() => {
        store.dispatch('setLoading', false);
        Notification.addNotification('Festivo creado correctamente', 'success', 6000);
        getPublicHolidays();
      })
      .catch(() => {
        Notification.addNotification('Error al crear el festivo', 'error', 6000);
        store.dispatch('setLoading', false);
      });
}

/* Modificación de un registro publicHoliday */
const updatePublicHoliday= async (publicHoliday) => {
  //await store.dispatch('setLoading', true);
  await PublicHolidayService.updatePublicHoliday(publicHoliday, publicHoliday.id)
      .then(() => {
        store.dispatch('setLoading', false);
        Notification.addNotification('Festivo actualizado correctamente', 'success', 6000);
        getPublicHolidays();
      })
      .catch(async () => {
        Notification.addNotification('Error al actualizar el festivo', 'error', 6000);
        await store.dispatch('setLoading', false);
      });
}
const resetVar = () => {
  newHolidayFormModel.value.publicHolidayId = null;
  newHolidayFormModel.value.selectedHolidayDate = null;
  newHolidayFormModel.value.selectedYearModal = null;
  newHolidayFormModel.value.selectedTypePublicHoliday = null;
  newHolidayFormModel.value.descriptionModal = null;
  newHolidayFormModel.value.checkedWorkplaces = [];

  newHolidayFormRules.value.selectedHolidayDate.error = false;
  newHolidayFormRules.value.selectedYearModal.error = false;
  newHolidayFormRules.value.selectedTypePublicHoliday.error = false;
  newHolidayFormRules.value.descriptionModal.error = false;
  newHolidayFormRules.value.checkedWorkplaces.error = false;
}
// eslint-disable-next-line no-unused-vars
const deleteDialog = (publicHoliday) => {
  selectedPublicHoliday.value = publicHoliday;
  deleteMsg.value = 'Festividad: \t' + publicHoliday.description + '\n'
      + 'Fecha: \t\t' + moment(publicHoliday.date).format('DD/MM/YYYY') + '\n'
      + 'De: \t\t\t' + publicHoliday.workplace.name;
  deleteDialogShown.value = true;
}
// eslint-disable-next-line no-unused-vars
const deletePublicHoliday = async(publicHoliday) => {
  await store.dispatch('setLoading', true);
  await PublicHolidayService.deletePublicHoliday(publicHoliday.id)
      .then(() => {
        Notification.addNotification('Festividad eliminada correctamente.', 'success', 6000);
        store.dispatch('setLoading', false);
        deleteDialogShown.value = false;
        resetVar();
        getPublicHolidays();
      })
      .catch(() => {
        Notification.addNotification('Error al intentar eliminar la festividad seleccionado.', 'error', 6000);
        store.dispatch('setLoading', false);
        deleteDialogShown.value = false;
        resetVar();
        getPublicHolidays();
      })
}


const formatDay = (date) => {
  moment.locale('es');
  if (date) {
    return moment(date).format('DD');
  }
  return '';
}
const formatMonthText = (date) => {
  moment.locale('es');
  if (date) {
    return moment(date).format('MMMM');
  }
  return '';
}

</script>

<style lang="scss" scoped>
//.table-holidays-calendar {
//  padding-top: 20px;
//
//  table {
//    width: 100%;
//    color: #858796;
//
//    th {
//      border: 1px solid #e3e6f0;
//      padding: 12px 12px 12px 20px;
//      font-size: 20px;
//      text-align: left;
//    }
//
//    tbody {
//
//      tr:nth-child(odd) {
//        td {
//          background-color: rgba(0, 0, 0, .05);
//        }
//      }
//
//      td {
//        border: 1px solid #e3e6f0;
//        padding: 12px;
//        padding-left: 20px;
//        background-color: white;
//        text-align: left;
//
//        .holiday-status-container {
//          display: inline-flex;
//          flex-flow: row nowrap;
//          justify-content: center;
//          align-items: center;
//          width: 100%;
//        }
//
//        .holiday-select {
//          width: 80%;
//        }
//
//        .holiday-button {
//          cursor: pointer;
//          margin-left: 20px;
//        }
//      }
//    }
//  }
//}

.new-holiday-button {
  margin-top: 20px;;
}

.pagination {
  padding: 0px 0px 20px 0px;
}

.item-modal {
  margin-top: 5px;
}

.work-center-label {
  display: inline-block;
}

.description-item {
  margin-bottom: 0;
}

.error-validation {
  border: 2px solid #F56C6C;
}

.public-holiday-label-modal {
  position: relative;
  bottom: 3px;
}
</style>
<style lang="scss">
.card-search {
  height: auto!important;
}
// Fix workcenter checkboxes style
form.el-form.newHolidayForm .modal-checkboxes-container .el-form-item__content {
  line-height: 24px !important;
}


.el-dialog{
  background: #f3f0fb !important;
}

.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body{
    margin-right: 5%;
  }

}
</style>
