
// Plugin dependencies
import router from "@/app/router/router";
import {ElNotification} from "element-plus";
import auth from "@/api/auth/auth";

// Default Messages
const defaultMessage = 'Se ha producido un error en nuestro sistema, por favor inténtelo de nuevo más tarde o contacte con nuestro servicio técnico.'
const notFoundMessage = 'No se ha encontrado el registro que buscabas, o no había resultados en la búsqueda.'
const notPermissionsMessage = 'No dispones de los permisos requeridos para realizar esta acción.'
const badCredentialsMessage = 'Los datos introducidos para iniciar sesión son incorrectos o no coinciden con tu usuario y contraseña.'


// Errors shown in console
function getErrorsOnConsole(err) {
  console.groupCollapsed(
    '%c🐞',
    'color: #dc6a6a; border: 2px solid #dc6a6a; padding: 4px; border-radius: 4px;',
    'A wild server error has appeared: ')
  console.info(err.data.message ? err.data.message : err.data.error ? `${err.data.error}: ${err.data.error_description}` : defaultMessage)
  console.groupCollapsed('👾 Debugger')
  console.log(err)
  console.table({ err })
  console.groupEnd();
  console.groupEnd();
}

async function errorResponseHandler(error, message, mode) {
  if (mode === 'auth') {
   if(error.response && error.response.data.code === 401) {
     ElNotification({
       type:'error',
       message:badCredentialsMessage
     })
      //notification.addNotification(badCredentialsMessage, 'error', 10000)
    }
    getErrorsOnConsole(error.response);
    return;
  }
  if(error.response && error.response.status === 401) {
    await auth.logout().then(async () => {
     await router.push({name: 'login'})
    })
    return;
  }else if (error.response) {
    const err = error.response

    getErrorsOnConsole(err)

    // Notification errors
    const msg = message ? message : err.data.message ? err.data.message : err.data.detail ? err.data.detail : err.data.error ? `${err.data.error}: ${err.data.error_description}` : null
    if(err.status) {
      switch (err.status) {
        case 400:
          ElNotification({
            type:'error',
            message: msg ? msg : defaultMessage,
            duration: 6000
          })
          break
        case 401:
             await router.push({ name: 'login' })
          break
        case 403:
          ElNotification({
            type:'warning',
            message: msg ? msg : notPermissionsMessage,
            duration: 6000
          })
          break
        case 404:
          ElNotification({
            type:'warning',
            message: msg ? msg : notFoundMessage,
            duration: 6000
          })
          break
        case 500:
          ElNotification({
            type:'error',
            message: msg ? msg : defaultMessage,
            duration: 6000
          })
          break
        default:
          ElNotification({
            type:'error',
            message: msg ? msg : defaultMessage,
            duration: 6000
          })
      }
    } else {
      ElNotification({
        type:'error',
        message: msg ? msg : defaultMessage,
        duration: 6000
      })
    }
  } else {
   // notification.addNotification(defaultMessage, 'error', 6000)
    ElNotification({
      type:'error',
      message:defaultMessage,
      duration: 6000
    })
  }
  ElNotification({
    type:'error',
    message: message ? message : defaultMessage,
    duration: 6000
  })

}


export default errorResponseHandler;
