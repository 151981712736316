<script setup>

import {defineEmits, onMounted, ref, watch, defineProps} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import priorityColors from "@const/priorityColors";
import stateColors from "@const/stateColors";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import TicketService from "@/api/services/ticket.service";
import Notification from "@/utils/plugins/NotificationService";
import moment from "moment";

const store = useStore();
const router = useRouter();
const emits = defineEmits(['totalTicket']);

const tickets = ref([]);
// const clientOrder = ref(null);
const typeOrder = ref(null);
// const stateOrder = ref(null);
// const descriptionOrder = ref(null);

const orderDescription = ref(null);
const orderType = ref(null);
const orderStatus = ref(null);

// const itemsPerPage = ref({id: 4, name: '15', value: 15});
const itemsPerPage = ref(5);
const page = ref(1);
const totalResults = ref(0);

const props = defineProps({
  refreshData: {
    type: Boolean,
    required: true
  },
  filters: {
    type: Object,
    required: true
  }
})

onMounted(async () => {
  await getTickets()
})

watch(() => props.refreshData, async () => {
  await getTickets()
})
watch(() => props.filters, async () => {
  await getTickets()
})

const gotoEdit = (id) => {
  router.push({name: 'update_internal_ticket', params:{id:id}})
}

const getTickets = async () => {
  const params = {
    itemsPerPage: itemsPerPage.value.value,
    page: page.value,
    'order[createdAt]': 'DESC',
    internal: true,
    ...props.filters
  }
  // const params = {
  //   itemsPerPage: itemsPerPage.value.value,
  //   page: page.value,
  //   'order[createdAt]': 'DESC',
  //   internal: true
  // }
  // if (clientOrder.value) {
  //   params['order[name]'] = clientOrder.value;
  // }
  // if (stateOrder.value) {
  //   params['order[state]'] = stateOrder.value;
  // }
  // if (descriptionOrder.value) {
  //   params['order[description]'] = descriptionOrder.value;
  // }
  // params['requestingUser.id'] = store.state.user.id
  await store.dispatch('setLoading', true);
  await TicketService.getAssignedTickets(store.getters.getUserLogged.id, params)
      .then((response) => {
        tickets.value = response.data['hydra:member'];
        // tickets.value.forEach(aTicket => {
        //   if (typeof aTicket.ticketStatusHistories !== 'undefined' && aTicket.ticketStatusHistories.length > 0) {
        //     let lastStatus = aTicket.ticketStatusHistories.find(item => {
        //       if (!item.dateEnd) {
        //         return item.status?.name ? item.status.name : null;
        //       }
        //       aTicket.lastStatus = lastStatus.status.name;
        //
        //     })
        //     if (!lastStatus) {
        //       lastStatus = aTicket.ticketStatusHistories.find(item => {
        //         if (item.dateStart && item.dateEnd && item.status && item.status.name === "Cerrado") {
        //           return item.status?.name ? item.status.name : null;
        //         }
        //       })
        //     }
        //     aTicket.lastStatus = lastStatus?.status?.name ? lastStatus.status.name : null;
        //   }
        //
        // })
        totalResults.value = tickets.value.length;
        emits('totalTicket', totalResults.value);
      })
      .catch((error) => {
        console.log(error);
        Notification.addNotification('Error al obtener listado de tickets asignados.', 'error', 6000);
      });
  await store.dispatch('setLoading', false);
}
const formatDateLocale = (value, loc = 'es') => {
  if (loc) {
    loc = 'es';
  }
  moment.updateLocale('es', {
    monthsShort: [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ]
  });
  if (value) {
    return moment(value).local(loc).format('DD/MM/YYYY HH:mm:ss');
  } else {
    return null;
  }
}

const typeColor = (type) => {
  if (type === 'Incidencia')
    return {color: '#F9156F', label: 'INCIDENCIA'};
  else if (type === 'Solicitud de servicio') {
    return {color: '#4AABC3', label: 'SERVICIO'};
  } else if (type === 'Solicitud de soporte (Duda)') {
    return {color: '#919191', label: 'SOPORTE'};
  }
  return 'grey'
}

const changeOrderType = () => {

}

</script>

<template>
  <div class="w-100">
    <el-row>
      <div v-if="tickets && tickets.length > 0" class="table pt-0">
        <table>
          <thead>
          <tr>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="">CÓDIGO
                  <font-awesome-icon  v-if="orderType === 'ASC'" icon="sort-alpha-up" />
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon v-else icon="sort" />
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="">TIPO
                  <font-awesome-icon  v-if="orderType === 'ASC'" icon="sort-alpha-up" />
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon v-else icon="sort" />
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center pointer">
                <div>
                  <img
                      width="21"
                      height="20"
                      src="@/assets/icons/group.svg"
                      alt=""> DESCRIPCIÓN
                  <font-awesome-icon
                      v-if="orderDescription === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderDescription === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th style="width: 230px;">
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderType()"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/calendar-blue.svg"
                      alt="fecha inicio"
                  >FECHA INICIO
                  <font-awesome-icon
                      v-if="typeOrder === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="typeOrder === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th style="width: 230px;">
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderType()"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/calendar-blue.svg"
                      alt="dias abierta"
                  >DIAS ABIERTA
                  <font-awesome-icon
                      v-if="typeOrder === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="typeOrder === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
<!--            <th>-->
<!--              <div-->
<!--                  class="d-flex justify-content-start align-items-center pointer"-->
<!--                  @click="changeOrderType()"-->
<!--              >-->
<!--                <div>-->
<!--                  <img-->
<!--                      width="21"-->
<!--                      height="20"-->
<!--                      class="mr-2"-->
<!--                      src="@/assets/icons/group.svg"-->
<!--                      alt="estado"-->
<!--                  >PRIORIDAD-->
<!--                  <font-awesome-icon-->
<!--                      v-if="orderType === 'ASC'"-->
<!--                      icon="sort-alpha-up"-->
<!--                  />-->
<!--                  <font-awesome-icon-->
<!--                      v-else-if="orderType === 'DESC'"-->
<!--                      icon="sort-alpha-down"-->
<!--                  />-->
<!--                  <font-awesome-icon-->
<!--                      v-else-->
<!--                      icon="sort"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </th>-->
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderType()"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="estado"
                  >ESTADO
                  <font-awesome-icon
                      v-if="orderType === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/user.svg"
                      alt="">ASIGNADO A
                  <font-awesome-icon
                      v-if="orderStatus === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon  v-else-if="orderStatus === 'DESC'"  icon="sort-alpha-down"/>
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>

          </tr>
          </thead>
          <tbody v-if="tickets && tickets.length && !store.state.loading">
          <tr
              v-for="ticket in tickets"
              :key="ticket.id"
          >
            <td>
              <el-tooltip
                  placement="top"
                  effect="light"
              >
                <template #content>
                  <span :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}"> Prioridad {{ ticket.ticketPriority.name }} </span>
                </template>
                <div class="d-flex justify-content-start align-items-center" :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}">
                  [{{ ticket.tag }}] - {{ ticket.id }}
                  <font-awesome-icon
                      class="ms-2 fa-2x"
                      :icon="priorityColors.getIcon(ticket.ticketPriority.name)"
                      :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}"/>
                </div>
              </el-tooltip>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                <div
                    class="type-color"
                    :style="{backgroundColor: typeColor(ticket.ticketType?.name).color}"
                /> {{ typeColor(ticket.ticketType?.name).label }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ ticket.issue }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ formatDateLocale(ticket.createdAt) }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ ticket.daysOpen }}
              </div>
            </td>
<!--            <td>-->
<!--              <div class="flex-row justify-content-between text-uppercase" :style="{color: priorityColors.getColor(ticket.ticketPriority.name)}">-->
<!--                {{ ticket.ticketPriority.name }}-->
<!--              </div>-->
<!--            </td>-->
            <td>
              <div class="flex-row justify-content-between" :style="{color: stateColors.getColor(ticket.status)}">
                {{ ticket.status }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-between align-items-center">
                <span v-if="ticket.assignedEmployee">{{ ticket.assignedEmployee.fullName }}</span>
                <span v-else>SIN ASIGNAR</span>
                <shared-button-plus
                    @onClick="gotoEdit(ticket.id)"
                    :title="'Editar ticket'"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="alert alert-info mt-3" v-if="!store.state.loading">
          No tiene tickets solicitados actualmente
        </p>
      </div>
    </el-row>
    <el-row v-if="tickets.length">
      <SharedPagination
          v-if="tickets && totalResults > itemsPerPage.value"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage.value"
          @change="page = $event;getTickets();"
      />
    </el-row>
  </div>


</template>

<style scoped lang="scss">
.type-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
