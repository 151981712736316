<template>
  <div
      class="employee-item"
      v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')">
    <HeaderTitle title="RrHh"></HeaderTitle>

    <div
        class="row"
        v-if="!profile">
      <div
          class="col-md-2"
          style="text-align: left">
        <routerLink
            :to="{ name: 'employees'}"
            class="return-btn btn btn-grey"
            style="padding-left: 0px; display:initial;">
          <font-awesome-icon icon="angle-left"></font-awesome-icon>
          Volver
        </routerLink>
      </div>
      <div class="col-md-8 list-header">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <hr>


    <!-- Form-->
    <form
        class="employee-form"
        @submit.prevent="saveEmployee(isEditingEmployee)"
        v-if="!store.state.loading">

      <div class="subtitle">
        <svg
            style="width: 30px;"
            xmlns="http://www.w3.org/2000/svg"
            class="subtitle__search-icon"
            viewBox="0 0 1024 1024">
          <path
              d="M641.8 737.4c-66.4 47.3-147.7 75.2-235.5 75.2-224.4 0-406.3-181.9-406.3-406.3s181.9-406.3 406.3-406.3c224.4 0 406.3 181.9 406.3 406.3 0 87.8-27.8 169.1-75.2 235.5l266.9 266.9c26.3 26.3 26.1 68.4 0 94.5l-1.1 1.1c-26 26-68.4 26.1-94.5 0l-266.9-266.9zM406.3 717c171.6 0 310.7-139.1 310.7-310.7s-139.1-310.7-310.7-310.7c-171.6 0-310.7 139.1-310.7 310.7s139.1 310.7 310.7 310.7v0z"/>
        </svg>
        <span class="subtitle__search-text">Undanitas</span>
      </div>
      <div class="row undanita">
        <div
            class="col-md-2"
            @click="openModalCropImage">
          <label class="pointer image">
            <!--                        <font-awesome-icon v-if="!image && !imagePreview" :icon="'upload'" title="Subir imagen"/>-->
            <img
                class="img-thumbnail rounded-circle"
                style="border: 1px solid blue;"
                v-if="!image && !imagePreview"
                src="~@/assets/images/user.png"
                title="Subir imagen"/>
            <img
                class="img-thumbnail rounded-circle"
                v-if="!image && imagePreview"
                :src="baseUrl + imagePreview"
                :alt="'Employee image'"
                title="Click para cambiar imagen">
            <img
                class="img-thumbnail rounded-circle"
                v-if="image"
                :src="imagePreview"
                :alt="'Employee image'"
                title="Click para cambiar imagen">
          </label>
        </div>
        <div class="col-10">
          <div class="row">
            <div class="col-md-6">
              <div class="form-item">
                <label for="">Nombre</label>
                <el-input
                    :type="'text'"
                    :param="'employeeName'"
                    :color="'light'"
                    :placeholder="'Nombre'"
                    v-model="employee.name"
                    />
                    <!-- Para hacer que al entrar si sale sin poner nada se valide automaticamente el nombre
                    @blur="v$.dni.$touch" -->
                    <p
                    v-if="v.name.$error"
                    class="invalid-feedback mb-0">
                  <small>
                    {{ v.name.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-item date-picker">
                <label
                    for=""
                    class="w-100">Fecha de alta</label>
                <el-date-picker
                    :name="'date-register'"
                    v-model="employee.dateRegister"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :prefix-icon="'el-icon-date'"
                    :picker-options="{firstDayOfWeek: 1}"
                    @blur="v$.dateRegister.$touch"
                />
                <p
                    v-if="v.dateRegister.$error && !employee.companyInterns"
                    class="invalid-feedback mb-0 error_date">
                  <small>
                    {{ v.dateRegister.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-item">
                <label
                    for=""
                    class="w-100">Apellidos</label>
                <el-input
                    :type="'text'"
                    v-model="employee.lastName"
                    :param="'employeeLastName'"
                    :color="'light'"
                    :placeholder="'Apellidos'"
                />
                <p
                    v-if="v.lastName.$error"
                    class="invalid-feedback mb-0">
                  <small>
                    {{ v.lastName.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
            <div v-if="employee.id && employee.active" class="col-md-6 text-right">
              <button
                  @click.prevent="openModalUnsubscribe()"
                  class="btn btn-block btn_red mt-4"
                  style="max-width: 200px; height: 42px">
                Dar de Baja
              </button>
            </div>
            <div v-else-if="employee.id" class="col-md-6 text-right">
              <div class="row">
                <div class="col-md-6">
                  <label class="w-70">Fecha de baja</label>
                  <el-date-picker
                    class="leave-date"
                    :name="'date-leave'"
                    v-model="employee.dateLeave"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :prefix-icon="'el-icon-date'"
                    :picker-options="{firstDayOfWeek: 1}"
                    />
                  </div>
                <div class="col-md-5">
                  <button
                    @click.prevent="openModalUnsubscribed()"
                    class="btn btn-block btn_red mt-4 btn_unsubscribe"
                    style="">
                    Ver motivo
                  </button>
              <!--              {{ employee.leaveType?.name }}-->
              <!--              {{ employee.dismissalObservations }}-->
            </div>
          </div>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="d-flex flex-nowrap flex-column">
              <label>Teletrabajo</label>
              <el-checkbox
                  v-model="employee.remote"
                  class="ml-4">Si
              </el-checkbox>
              <p
                  v-if="v.remote.$error"
                  class="invalid-feedback mb-0">
                <small>
                  {{ v.remote.$errors[0].$message }}
                </small>
              </p>
              <!--             <el-radio-group v-model="remote" class="ml-4">-->
              <!--                <el-radio value="Yes" size="large">Si</el-radio>-->
              <!--                <el-radio value="No" size="large">No</el-radio>-->
              <!--              </el-radio-group>-->
              <!--   <input
                     type="radio"
                     class="align-self-center radio-larg"
                     checked
                     v-show="remoteOneButton"
                     name="confirm-remote"
                     id="id-confirm-remote"
                     value="id-confirm-remote"
                     @click.prevent="remoteOneButton = !remoteOneButton"
                 />
                 <input
                     type="radio"
                     class="align-self-center radio-larg"
                     v-show="!remoteOneButton"
                     name="confirm-remote"
                     id="id-confirm-remote"
                     value="id-confirm-remote"
                     @click.prevent="remoteOneButton = !remoteOneButton"
                 />-->
            </div>
          </div>
          <div class="col-md-2">
            <div class="d-flex flex-nowrap flex-column">
              <label>Becario/Prácticas</label>
              <el-checkbox
                  v-model="employee.companyInterns"
                  class="ml-4" >Si
              </el-checkbox>
              <p
                  v-if="v.companyInterns.$error"
                  class="invalid-feedback mb-0">
                <small>
                  {{ v.companyInterns.$errors[0].$message }}
                </small>
              </p>
            </div>
          </div>
            <div v-if="employee.companyInterns" class="col-md-4">
              <div class="d-flex flex-nowrap flex-column">
                <label>Inicio de las prácticas</label>
                <el-date-picker
                    :name="'date-start'"
                    :picker-options="{firstDayOfWeek: 1}"
                    :prefix-icon="'el-icon-date'"
                    v-model="employee.dateStartIntern"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    @change="changeDate"
                    placeholder="Fecha Inicio"
                    @blur="v$.dateStartIntern.$touch"
                />

                <p
                    v-if="v.dateStartIntern.$error"
                    class="invalid-feedback mb-0 error_date">
                  <small>
                    {{ v.dateStartIntern.$errors[0].$message }}
                  </small>
                </p>

              </div>
            </div>
            <div v-if="employee.companyInterns" class="col-md-4">
              <div class="d-flex flex-nowrap flex-column">
                <label>Fin de las prácticas</label>
                <el-date-picker
                    :name="'date-end'"
                    :picker-options="{firstDayOfWeek: 1}"
                    :prefix-icon="'el-icon-date'"
                    :default-value="defaultDate"
                    :disabled-date="disabledDate"
                    v-model="employee.dateEndIntern"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    @blur="v$.dateEndIntern.$touch"
                    placeholder="Fecha Fin"
                />
                <p
                    v-if="v.dateEndIntern.$error"
                    class="invalid-feedback mb-0 error_date">
                  <small>
                   {{ v.dateEndIntern.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
        </div>
      </div>

      <br>

      <div class="subtitle__personal-data">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="subtitle__personal-data-icon"
            viewBox="0 0 1024 1024">
          <path
              d="M1021.3 979.3c-12.3-54.5-105-392.7-509.7-392.7-437.9 0-510.5 395.9-511.2 399.9-1.6 9.3 0.9 18.9 7.1 26.1 6.1 7.3 15.1 11.4 24.5 11.4h959.9c17.6 0 32-14.4 32-32.1 0-4.5-0.9-8.8-2.7-12.7v0zM73.2 959.9c27.9-90.2 127.7-309.2 438.5-309.2 310.9 0 410.6 219 438.5 309.2h-876.9z"/>
          <path
              d="M511.7 526.3c145 0 263-118 263-263.1 0-145.2-118-263.2-263-263.2s-262.9 118-262.9 263.1c0 145.2 117.9 263.2 262.9 263.2v0zM511.7 64.2c109.7 0 198.8 89.2 198.8 199 0 109.7-89.1 198.9-198.8 198.9-109.6 0-198.7-89.2-198.7-198.9 0-109.8 89.1-199 198.7-199v0z"/>
        </svg>
        <span class="subtitle__personal-data-text">DATOS PERSONALES</span>
      </div>
      <div class="personal-data">
        <div class="row">
          <div class="col-md-6">
            <div class="form-item">
              <label class="w-100">DNI</label>
              <el-input
                  v-model="employee.dni"
                  :placeholder="'DNI/NIE/NIF'"
              />
              <p
                  v-if="v.dni.$error"
                  class="invalid-feedback mb-0">
                <small>
                  {{ v.dni.$errors[0].$message }}
                </small>
              </p>
            </div>

          </div>
          <div class="col-md-2 d-inline-flex align-items-center text-left">
            <div class="form-item date-picker">
              <label class="w-100">Fecha de nacimiento</label>
              <el-date-picker
                  class="birthday"
                  :name="'birthday'"
                  v-model="employee.birthday"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="'el-icon-date'"
                  :picker-options="{firstDayOfWeek: 1}"
              />
              <p
                  v-if="v.birthday.$error"
                  class="invalid-feedback mb-0 error_date">
                <small>
                  {{ v.birthday.$errors[0].$message }}
                </small>
              </p>
            </div>


          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-item">
                <label class="w-100">Teléfono Empresa</label>
                <el-input
                    v-model="employee.phone"
                    :type="'text'"
                    placeholder="Teléfono Empresa"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-item">
                <label class="w-100">Teléfono Personal</label>
                <el-input
                    v-model="employee.phonePersonal"
                    :type="'text'"
                    :placeholder="'Teléfono Personal'"
                />
                <p
                    v-if="v.phonePersonal.$error"
                    class="invalid-feedback mb-0">
                  <small>
                    {{ v.phonePersonal.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-item">
                <label class="w-100">Correo electrónico</label>
                <el-input
                    v-model="employee.email"
                    type="email"
                    :placeholder="'Correo electrónico'"
                />
                <p
                    v-if="v.email.$error"
                    class="invalid-feedback mb-0">
                  <small>
                    {{ v.email.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-item">
                <label class="w-100">Correo personal</label>
                <el-input
                    v-model="employee.emailPersonal"
                    type="email"
                    :placeholder="'Correo personal'"
                />
                <p
                    v-if="v.emailPersonal.$error"
                    class="invalid-feedback mb-0">
                  <small>
                    {{ v.emailPersonal.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>


        <!--      <hr>-->

        <div class="row postal-data">
          <div class="col-md-2">
            <h3 class="h3-employee">Dirección postal</h3>
          </div>
          <div class="d-inline left">
            <div class="">
              <font-awesome-icon
                  class="btn_icon blue_btn fa-2x eye pointer"
                  icon="eye"
                  title="Ver/Editar"
                  style="margin-left: 5px"
                  @click="openModalEmployeeAddress()"/>
            </div>
          </div>
        </div>

        <div class="subtitle__personal-data">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="subtitle__personal-data-icon"
              viewBox="0 0 1024 1024">
            <path
                d="M539.4 226.3c-0.7-0.8-1.3-1.5-2.1-2.3-8.9-8-9.2-5.7 0.6-14.7 7.3-6.7 16.1-11.9 24-17.6 21.1-15.3 35-35.7 44.4-59.9 21.1-54.4-41-92-72.2-124.2-22.5-23.3-58 12.2-35.5 35.5 17.4 18 39.8 32.6 54.9 52.6 16.9 22.4-8.9 47.1-26.6 59.5-27.3 19.3-51 44.8-45.5 72.3-122.7 11.5-236.1 78.7-236.1 197.2v332.7c0 147 119.6 266.6 266.6 266.6 147 0 266.6-119.6 266.6-266.6v-332.8c0-129.4-120.4-192.1-239.2-198.4zM295.6 424.7c0-88 95.5-138.9 194.4-147.4v306.9c-90.2-4.5-163.5-25.1-194.4-35.1v-124.4zM728.4 757.4c0 119.3-97.1 216.4-216.4 216.4-119.3 0-216.4-97.1-216.4-216.4v-155.6c44.8 13.5 129.2 34.1 228.9 34.1 63.8 0 134-8.5 203.9-32.6v154.2zM728.4 549.9c-63.5 24.3-128.3 33.8-188.2 35v-308.4c96.8 5.8 188.2 52.2 188.2 148.2v125.2zM468.4 303.3v254.5c-39 6.9-153.6-25.2-153.6-25.2-16.9-178.4 49.9-209.8 153.6-229.3z"/>
          </svg>
          <span class="subtitle__personal-data-text">DEPARTAMENTOS Y HORARIOS</span>
        </div>

        <div class="company-data">
          <div class="row">
            <div class="col-6">

              <div
                  class="row"
                  style="margin-top: 10px">
                <div class="col-md-4 d-inline-flex align-items-center">
                  <label
                      class="label-employee"
                      for="department">Departamento</label>
                </div>
                <div class="col-md-8">
                  <div class="form-item">
                    <el-select
                        v-model="department"
                        placeholder="Departamento"
                        @change="getJobsFromDepartment(department)"
                        :value-key="'@id'"
                    >
                      <el-option
                          v-for="item in departments"
                          :key="item.id"
                          :label="item.name"
                          :value="item"
                      />
                    </el-select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 d-inline-flex align-items-center">
                  <label
                      class="label-employee"
                      for="job">Puesto de Trabajo</label>
                </div>
                <div class="col-md-8">

                  <!--                                    @selectItem="jobType = $event; updateEmployeeJob(employee, $event);"-->
                  <!--                                    :selected="employee.job && employee.job.jobType ? employee.job.jobType : jobType"-->
                  <div class="form-item">
                    <el-select
                        v-model="employee.job"
                        placeholder="Puesto de trabajo"
                        :value-key="'@id'"
                    >
                      <el-option
                          v-for="item in jobs"
                          :key="item.id"
                          :label="item.name"
                          :value="item"
                      />
                    </el-select>
                  </div>
                </div>
              </div>

              <div
                  class="row"
                  style="margin-top: 10px">
                <div class="col-md-4 d-inline-flex align-items-center">
                  <label
                      class="label-employee"
                      for="workplace">Centro de Trabajo</label>
                </div>
                <div class="col-md-8">
                  <div class="form-item">
                    <el-select
                        v-model="workplace"
                        placeholder="Centro de Trabajo"
                        @change="employee.workplace = workplace"
                        :value-key="'@id'"
                    >
                      <el-option
                          v-for="item in workplaces"
                          :key="item.id"
                          :label="item.name"
                          :value="item"
                      />
                    </el-select>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-6">
              <div
                  class="row"
                  style="margin: 10px 0 0 10px">
                <div class="form-item">
                  <el-input
                      v-model="textCalendar"
                      :label="'Horario'"
                      :placeholder="'Horario'"
                      :readonly="true"
                  />
                </div>
              </div>
              <div
                  class="row"
                  v-if="employee && employee.id">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                  <button
                      @click.prevent="openModalCustomSchedule()"
                      class="btn btn-block btn_yellow"
                      style="width: 100%; height: 42px;">
                    Personalizar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>


        <!--                        Contactos de emergencia-->
        <div class="subtitle__personal-data">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="subtitle__personal-data-icon"
              style="width: 45px; height: 45px;"
              viewBox="0 0 1024 1024">
            <path
                d="M1013.7 795.7h-480.5c-5.3 0-9.7-4.4-9.7-9.7v-14c0-10.4 0-20.8 0-31.4 0-29 0-59 0.2-88.3 0-4.1-0.5-5.3-5.3-8-33.1-18.4-66.7-37.5-98.9-56.1-9.4-5.3-18.9-10.6-28.1-16.2-2.2-1.2-3.4-2.7-4.4-3.9-0.2-0.5-0.7-1-1.2-1.5-1.9-2.2-2.9-5.3-2.4-8.2s2.4-5.6 4.8-7c35.8-19.3 61.7-45.9 79.1-81 1.2-2.4 3.1-4.1 5.8-4.8 2.4-0.7 5.3-0.5 7.5 0.7 2.7 1.5 5.6 2.9 8.2 4.1 6.3 3.1 12.6 6.3 18.4 10.6 5.3 3.9 8 3.6 13.1-0.5 3.6-2.9 7.5-5.3 11.6-7.7 1.9-1.2 3.9-2.4 6-3.6 2.2-1.5 5.1-1.9 7.5-1.2 2.7 0.7 4.8 2.4 6 4.8 20.1 37.2 46.4 63.1 83.2 80.8 3.6 1.7 5.8 5.3 5.3 9.4-0.2 3.9-2.9 7.3-6.5 8.5-7.5 2.4-12.3 5.6-14 8.9 0 0 0 0 0 0.2 7-3.6 14.3-7.3 21.3-11.1 16.9-9.2 33.4-7.3 47.4 5.6 6 5.6 12.1 11.4 18.6 17.2 1 0.7 1.7 1.7 2.7 2.4 3.9-3.1 7.5-6.3 10.4-9.4 11.4-12.8 23.7-7 28.3-4.8 36 17.2 72.8 34.1 108.1 50.5 16.9 8 33.9 8.9 51.5 2.9 5.8-1.9 16.2-1.9 21.8 3.9 37.7 39.9 75.2 84.4 93.8 141 0.7 2.4 0.7 4.6 0.5 6 0 0.2 0 0.5 0 1-0.5 5.6-4.8 9.9-10.2 9.9zM542.9 776.3h458.5c-16.9-45.5-46.4-82.9-85.4-124.1-0.7 0-1.9 0-2.7 0.2-22.2 7.5-44.5 6-65.8-3.9-35.5-16.4-72.1-33.6-108.3-50.5-2.9-1.5-3.9-1.5-3.9-1.5s-0.5 0.2-1.5 1.5c-4.1 4.6-8.7 8.5-13.3 12.1-1.9 1.5-3.6 3.1-5.6 4.8-3.6 3.4-9.4 3.1-13.1-0.2-3.1-2.9-6-5.6-8.9-8.2-6.3-5.8-12.3-11.6-18.4-17.2-8-7.3-15.5-8-25.1-2.9-7.7 4.1-15.7 8.2-23.5 12.3-3.4 1.7-7 3.6-10.4 5.3-2.4 1.2-5.3 1.5-8 0.5s-4.6-3.1-5.6-5.6c-3.9-10.2-4.1-19.3-0.5-26.8 1.7-3.6 4.4-7 8-9.9-29.3-17.4-52.5-40.9-70.4-71.3-2.9 1.7-5.1 3.1-7.3 4.8-11.8 9.7-24.2 10.2-36.5 1.2-4.6-3.4-9.9-6-15.7-8.9-16.7 30.2-39.4 54.4-69.4 73 6 3.6 12.3 7 18.4 10.6 32.2 18.4 65.5 37.7 98.7 55.9 10.9 6 15.5 13.5 15.2 25.2-0.2 29.5-0.2 59.2-0.2 88.3 0 10.4 0 20.8 0 31.4v3.9z"/>
            <path
                d="M315.1 573.4c-45.5 0-88.3-17.9-120.9-50.5s-50.8-75.7-51-121.4c-0.2-46.2 17.7-89.7 50.3-122.6 32.6-32.6 75.9-50.5 121.9-50.5 46.2 0.2 89.2 18.1 121.6 50.8s50.3 75.9 50.1 121.6c0 45.5-17.9 88.8-50.5 121.4-32.6 32.9-75.7 51-120.9 51-0.2 0.2-0.2 0.2-0.5 0.2zM314.8 247.7c-40.6 0-78.8 16-107.6 44.7-29 29.3-45 68-44.7 108.8 0.5 84.2 68.9 152.6 152.6 152.6 0 0 0.2 0 0.2 0 82.7-0.2 152.4-70.4 152.4-153.3 0 0 0 0 0 0 0.2-40.6-15.5-78.8-44.3-107.9s-67-45-108.1-45c0 0-0.2 0-0.5 0z"/>
            <path
                d="M709.2 573.4c-0.2 0-0.5 0-0.7 0-45.2-0.2-88.3-18.4-120.9-51.3s-50.8-75.9-50.8-121.2c0 0 0 0 0 0 0-45.7 17.9-89 50.1-121.6 32.4-32.9 75.9-51 122.4-51 0.2 0 0.2 0 0.5 0 45.9 0 89.2 18.1 121.4 50.5s50.1 75.5 50.3 121.6c0.2 45.2-17.7 88.5-50.8 121.6-32.9 33.1-76.2 51.3-121.4 51.3zM556.2 401c0 82.7 69.6 152.8 152.4 153.1 0.2 0 0.5 0 0.5 0 40.1 0 78.6-16.2 107.9-45.5s45.2-67.7 45-107.9c-0.5-85.6-67.5-152.8-152.6-153.1 0 0-0.2 0-0.2 0-41.1 0-79.8 16-108.6 45.2-28.3 29-44 67.5-44.3 108.1v0z"/>
            <path
                d="M488.9 795.4h-479.3c-3.1 0-6-1.5-7.7-3.9s-2.4-5.6-1.5-8.7c12.3-39.7 31.7-74.7 59.2-106.9 5.1-6 10.6-12.1 15.7-17.7 5.1-5.8 10.4-11.6 15.5-17.4 4.6-5.3 12.1-11.8 25.2-7 16.7 6.3 33.6 5.3 52-3.4 16-7.5 31.9-15 47.9-22.5 19.8-9.2 40.1-18.9 60.2-28.3 3.9-1.7 15.2-7.3 26.1 4.4 3.4 3.6 7.3 7 11.4 10.6l22.2-20.6c13.5-12.3 28.8-14.5 44.3-5.8 9.9 5.6 19.8 11.4 29.5 16.9 10.9 6.3 22.2 12.8 33.4 19.1 10.9 5.8 21.8 11.4 33.6 17.2 5.6 2.7 11.1 5.6 16.9 8.5 3.1 1.7 5.3 5.1 5.3 8.7v147.3c-0.2 5.1-4.6 9.4-9.9 9.4zM22.9 776.1h456.1v-131.8c-3.9-1.9-7.7-3.9-11.6-5.8-11.8-5.8-23.2-11.6-34.3-17.7-11.4-6.3-22.7-12.8-33.9-19.3-9.4-5.6-19.3-11.4-29-16.7-8.2-4.6-14.3-3.6-21.8 3.1l-28.5 26.4c-3.6 3.4-9.4 3.4-13.1 0-2.2-1.9-4.1-3.6-6-5.3-4.6-4.1-8.9-7.7-13.1-12.1-0.5-0.5-1-1-1-1-0.2 0-1.2 0.2-2.7 1-20.1 9.4-40.6 19.1-60.5 28.3-16 7.5-31.9 14.8-47.6 22.2-23 10.9-45.7 12.1-67.2 3.9-1-0.2-1.5-0.5-1.7-0.5-0.2 0.2-1 0.7-1.9 1.9-5.1 6-10.6 12.1-16 17.9-5.1 5.6-10.4 11.6-15.5 17.4-22.2 27.3-39.2 56.1-50.8 88z"/>
            <path
                d="M886.5 634.4c-2.9 0-5.8-0.2-8.7-0.5h-0.5c-2.2-0.2-5.3-0.5-8.2-1.9-30.7-13.8-61.7-27.8-92.4-41.6l-17.4-8c-3.4-1.5-5.6-4.6-5.6-8.2l-0.2-3.4c-0.2-4.4 2.4-8.2 6.3-9.7 39.9-14.3 71.3-39.7 93.6-75.7 2.7-4.1 8-5.8 12.3-3.6 5.6 2.7 11.4 5.3 16.7 7.7 12.3 5.8 24.2 11.1 35.8 17.2 29.5 15.7 43.5 49.6 33.4 80.3-9.2 29.3-34.8 47.4-65.1 47.4zM784 572.2l1 0.5c30.7 13.8 61.4 27.8 92.4 41.6 0.2 0 1.7 0.2 2.4 0.2h0.5c24.9 2.9 45.7-10.4 53.4-33.9 7.3-21.8-2.9-45.7-24.2-57.1-11.1-6-22.7-11.1-34.8-16.7-3.1-1.5-6.3-2.9-9.2-4.4-20.8 31-48.1 54.2-81.5 69.6z"/>
            <path
                d="M134.2 635.1c-22 0-44.7-13.1-57.6-34.8-16.4-27.8-10.9-64.6 12.3-82 13.5-9.9 28.8-17.4 43.5-24.4 5.3-2.4 10.6-5.1 15.7-7.7 6-3.1 15.7-0.7 19.6 5.1 22.7 33.6 54.2 57.6 93.1 71.1 4.4 1.5 7.3 6 6.3 10.6l-0.5 3.1c-0.5 3.1-2.7 6-5.6 7.3-10.6 4.8-21.3 9.7-31.7 14.5-24.7 11.4-50.3 23.2-76.2 33.9-6 2.4-12.6 3.4-19.1 3.4zM153.8 505c-4.4 2.2-8.7 4.4-12.8 6.3-14.5 7-28.3 13.8-40.4 22.7-15.2 11.4-18.6 37.2-7.3 56.6 11.6 19.6 35.8 30.2 52.7 23.2 25.4-10.4 50.8-22 75.2-33.4 5.1-2.4 10.2-4.6 15.2-7-33.6-15.2-61.4-38.2-82.7-68.4zM257.3 574.4v0 0z"/>
          </svg>

          <span class="subtitle__personal-data-text">PERSONAS DE CONTACTO</span>
        </div>

        <div v-if="employee.id">
          <div>
            <div
                class="row item"
                style="text-align: left; margin-left: 5px;">
              <div class="col-md-4">
                <span>Nombre</span>
              </div>
              <div class="col-md-3">
                <span>Relación</span>
              </div>
              <div class="col-md-4">
                <span>Teléfono</span>
              </div>
              <div class="col-md-1"></div>
            </div>
            <!--                                <ul v-if="employee.emergencyContacts && employee.emergencyContacts.length > 0">-->
            <ul>
              <!--                                    <div v-if="!employee.emergencyContacts || employee.emergencyContacts && employee.emergencyContacts.length < 4">-->
              <li
                  v-for="(emergencyContact, index) in emergencyContacts"
                  :key="emergencyContact.id">
                <div class="row item">
                  <div class="col-md-4">
                    <div class="form-item">
                      <el-input
                          v-model="emergencyContact.name"
                          :id="'emergencyContactName' + index"
                          :color="'light'"
                          :placeholder="'Nombre'"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <el-input
                        v-model="emergencyContact.relationship"
                        :id="'emergencyContactRelationship' + index"
                        :placeholder="'Relacción'"
                    />
                  </div>
                  <div class="col-md-4">
                    <div class="form-item">
                      <el-input
                          v-model="emergencyContact.phone"
                          :id="'emergencyContactPhone' + index"
                          :placeholder="'Teléfono'"
                      />
                    </div>
                  </div>
                  <div
                      class="col-md-1"
                      style="justify-content: left">

                    <div
                        class="pointer d-inline-flex"
                        v-if="index===0">
                      <font-awesome-layers
                          v-if="emergencyContacts.length < 3"
                          class="fa-2x"
                          style="margin-left: 5px"
                          title="Añadir contacto"
                          @click="addEmergencyContact(emergencyContact)">
                        <font-awesome-icon
                            icon="circle"
                            style="color: #f7d20e"/>
                        <font-awesome-icon
                            icon="plus"
                            class="fa-inverse"
                            transform="shrink-6"/>
                      </font-awesome-layers>

                      <font-awesome-layers
                          class="fa-2x"
                          style="margin-left: 5px"
                          title="Eliminar contacto"
                          @click="removeEmergencyContact(emergencyContact, index)">
                        <font-awesome-icon
                            icon="circle"
                            style="color:red"/>
                        <font-awesome-icon
                            icon="times"
                            class="fa-inverse"
                            transform="shrink-6"/>
                      </font-awesome-layers>
                    </div>

                    <div>
                      <div
                          class="pointer"
                          v-if="index > 0 && emergencyContacts.length > 0">
                        <font-awesome-icon
                            class="btn_icon delete-btn fa-2x btn-group"
                            icon="times"
                            title="Eliminar contacto"
                            style="margin-left: 5px"
                            @click="removeEmergencyContact(emergencyContact, index)"/>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

        </div>
        <hr>
        <br>
        <!--            Jefe de Equipo-->
        <div
            class="row item"
            v-if="employee.teamManager">
          <div class="col-md-6">
            <div class="form-item">
              <el-input
                  v-model="teamManagerName"
                  :label="'Reporta a'"
                  :placeholder="'Reporta a'"
                  :readonly="true"
              />
            </div>
          </div>
        </div>
        <div
            v-else
            class="row">
          <div class="col-md-2">
            <h3 class="h3-employee">Jefe de Equipo</h3>
          </div>
        </div>


        <div
            class="row"
            v-if="employee.id">

          <div class="subtitle__personal-data">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="subtitle__personal-data-icon"
                style="width: 45px; height: 45px;"
                viewBox="0 0 1024 1024">
              <path
                  d="M559.4 21.4c-12.2-13.1-29.5-20.6-47.4-20.6s-35.2 7.5-47.4 20.6c-12.2 13.1-18.6 30.8-17.4 48.7l3.3 50.1c0 0 0 0 0 0 1.3 19.8 11.8 37.9 28 48.5 10.2 6.6 21.9 9.9 33.6 9.9s23.4-3.3 33.6-9.9c16.2-10.5 26.7-28.7 28-48.5l3.3-50.1c1.2-17.9-5.2-35.6-17.4-48.7zM536.1 67.4l-3.3 50.1c-0.5 7-4 13.4-9.5 16.9-6.9 4.5-15.9 4.5-22.8 0-5.5-3.6-9-9.9-9.5-16.9l-3.3-50.1c-0.4-6.7 1.9-13.2 6.5-18.1 4.6-4.9 10.9-7.6 17.7-7.6s13 2.7 17.7 7.7c4.6 4.9 6.9 11.4 6.5 18.1z"/>
              <path
                  d="M673.3 298.4l-5.7-31.3c-7.5-41.1-40-74-81.1-81.7-6.2-1.2-12.5-1.8-18.8-1.8h-17.1c-5.4 0-10.6 2.2-14.4 6-6.5 6.5-15.1 10.1-24.3 10.1s-17.8-3.6-24.3-10c-3.8-3.8-9-6-14.4-6h-17.1c-6.3 0-12.6 0.6-18.8 1.8-41 7.7-73.6 40.6-81.1 81.7l-5.7 31.3c-1.1 5.9 0.5 12.1 4.4 16.7 3.9 4.6 9.6 7.3 15.7 7.3h282.5c6 0 11.8-2.7 15.7-7.3 3.9-4.6 5.5-10.8 4.4-16.7zM395.2 281.6l1.3-7.3c4.5-24.6 23.9-44.2 48.5-48.9 3.7-0.7 7.5-1.1 11.3-1.1h9.4c13.2 10.4 29.4 16 46.4 16 17 0 33.2-5.6 46.4-16h9.4c3.8 0 7.6 0.4 11.3 1.1 24.5 4.6 44 24.3 48.5 48.9l1.3 7.3h-233.7z"/>
              <path
                  d="M559.4 722.1c-12.2-13.1-29.5-20.6-47.4-20.6s-35.2 7.5-47.4 20.6c-12.2 13.1-18.6 30.8-17.4 48.7l3.3 50.1c1.3 19.8 11.8 37.9 28 48.5 10.2 6.6 21.9 9.9 33.6 9.9 11.7 0 23.4-3.3 33.6-9.9 16.2-10.5 26.7-28.7 28-48.5l3.3-50.1c1.2-17.9-5.2-35.6-17.4-48.7zM536.1 768.1l-3.3 50.1c-0.5 7-4 13.3-9.5 16.9-6.9 4.5-15.9 4.5-22.8 0-5.5-3.6-9-9.9-9.5-16.9l-3.3-50.1c-0.4-6.7 1.9-13.2 6.5-18.1 4.6-4.9 10.9-7.6 17.7-7.6s13 2.7 17.7 7.7c4.6 4.9 6.9 11.4 6.5 18.1z"/>
              <path
                  d="M673.3 999.1l-5.7-31.3c-7.5-41.1-40-74-81.1-81.7-6.2-1.2-12.5-1.8-18.8-1.8h-17.1c-5.4 0-10.6 2.2-14.4 6-13.4 13.4-35.1 13.4-48.5 0-3.8-3.8-9-6-14.4-6h-17.1c-6.3 0-12.6 0.6-18.8 1.8-41 7.7-73.6 40.6-81.1 81.7l-5.7 31.3c-1.1 5.9 0.5 12.1 4.4 16.7 3.9 4.6 9.6 7.3 15.7 7.3h282.5c6 0 11.8-2.7 15.7-7.3 3.9-4.6 5.5-10.8 4.4-16.7zM395.2 982.3l1.3-7.2c4.5-24.6 23.9-44.2 48.5-48.9 3.7-0.7 7.5-1.1 11.3-1.1h9.4c27.1 21.3 65.6 21.3 92.8 0h9.4c3.8 0 7.6 0.4 11.3 1.1 24.5 4.6 44 24.3 48.5 48.9l1.3 7.3h-233.7z"/>
              <path
                  d="M209.1 371.8c-12.2-13.1-29.5-20.6-47.4-20.6s-35.2 7.5-47.4 20.6c-12.2 13.1-18.6 30.8-17.4 48.7l3.3 50.1c1.3 19.8 11.7 37.9 28 48.5 10.2 6.6 21.9 9.9 33.6 9.9s23.4-3.3 33.6-9.9c16.2-10.5 26.7-28.7 28-48.5l3.3-50.1c1.2-17.9-5.2-35.6-17.4-48.7zM185.8 417.8l-3.3 50.1c-0.5 7-4 13.3-9.5 16.9-6.9 4.5-15.9 4.5-22.8 0-5.5-3.6-9-9.9-9.5-16.9l-3.3-50.1c-0.4-6.7 1.9-13.2 6.5-18.1 4.6-4.9 10.9-7.7 17.7-7.7s13 2.7 17.7 7.7 6.9 11.4 6.5 18.1z"/>
              <path
                  d="M323 648.7l-5.7-31.3c-7.5-41.1-40-74-81.1-81.7-6.2-1.2-12.5-1.8-18.8-1.8h-17.1c-5.4 0-10.6 2.2-14.4 6-13.4 13.4-35.1 13.4-48.5 0-3.8-3.8-9-6-14.4-6h-17.1c-6.3 0-12.6 0.6-18.8 1.8-41 7.7-73.6 40.6-81.1 81.7l-5.7 31.3c-1.1 5.9 0.5 12.1 4.4 16.7 3.9 4.6 9.6 7.3 15.7 7.3h282.5c6 0 11.8-2.7 15.7-7.3 3.9-4.6 5.5-10.8 4.4-16.7zM44.8 632l1.3-7.3c4.5-24.6 23.9-44.2 48.5-48.9 3.7-0.7 7.5-1.1 11.3-1.1h9.4c13.2 10.4 29.4 16 46.4 16s33.2-5.6 46.4-16h9.4c3.8 0 7.6 0.4 11.3 1.1 24.5 4.6 44 24.3 48.5 48.9l1.3 7.3h-233.7z"/>
              <path
                  d="M909.8 371.8c-12.2-13.1-29.5-20.6-47.4-20.6-17.9 0-35.2 7.5-47.4 20.6-12.2 13.1-18.6 30.8-17.4 48.7l3.3 50.1c1.3 19.8 11.7 37.9 28 48.5 10.2 6.6 21.9 9.9 33.6 9.9 11.7 0 23.4-3.3 33.6-9.9 16.2-10.5 26.7-28.7 28-48.5l3.3-50.1c1.2-17.9-5.2-35.6-17.4-48.7zM886.5 417.8l-3.3 50.1c-0.5 7-4 13.3-9.5 16.9-6.9 4.5-15.8 4.5-22.8 0-5.5-3.6-9-9.9-9.5-16.9l-3.3-50.1c-0.4-6.7 1.9-13.2 6.5-18.1s10.9-7.6 17.7-7.6c6.8 0 13 2.7 17.7 7.7 4.6 4.9 6.9 11.4 6.5 18.1z"/>
              <path
                  d="M1023.7 648.7l-5.7-31.3c-7.5-41.1-40-74-81.1-81.7-6.2-1.2-12.5-1.8-18.8-1.8h-17.1c-5.4 0-10.6 2.2-14.4 6-13.4 13.4-35.1 13.4-48.5 0-3.8-3.8-9-6-14.4-6h-17.1c-6.3 0-12.6 0.6-18.8 1.8-41 7.7-73.6 40.6-81.1 81.7l-5.7 31.3c-1.1 5.9 0.5 12.1 4.4 16.7 3.9 4.6 9.6 7.3 15.7 7.3h282.5c6 0 11.8-2.7 15.7-7.3 3.9-4.6 5.5-10.8 4.4-16.7zM745.5 632l1.3-7.3c4.5-24.6 23.9-44.2 48.5-48.9 3.7-0.7 7.5-1.1 11.3-1.1h9.4c13.2 10.4 29.4 16 46.4 16s33.2-5.6 46.4-16h9.4c3.8 0 7.6 0.4 11.3 1.1 24.5 4.6 44 24.3 48.5 48.9l1.3 7.3h-233.7z"/>
              <path
                  d="M406 132.5c-3.6-10.7-15.2-16.4-25.9-12.8-80.8 27.3-152.8 80.8-202.6 150.4-6.6 9.2-4.4 21.9 4.7 28.5 3.6 2.6 7.7 3.8 11.9 3.8 6.4 0 12.6-3 16.6-8.5 44.9-62.8 109.8-110.9 182.5-135.5 10.7-3.6 16.4-15.2 12.8-25.9z"/>
              <path
                  d="M847.1 270.1c-35.1-49-81.4-90.5-134-119.9-9.8-5.5-22.3-2-27.8 7.8-5.5 9.8-2 22.3 7.8 27.8 47.4 26.5 89.1 63.9 120.8 108.1 4 5.6 10.2 8.5 16.6 8.5 4.1 0 8.3-1.2 11.9-3.8 9.2-6.6 11.3-19.3 4.7-28.5z"/>
              <path
                  d="M655 123.4c-3.5-1.3-7-2.5-10.5-3.7-10.7-3.6-22.2 2.1-25.9 12.8-3.6 10.7 2.1 22.2 12.8 25.9 3.2 1.1 6.4 2.2 9.5 3.4 2.3 0.9 4.7 1.3 7.1 1.3 8.3 0 16.1-5.1 19.1-13.3 3.9-10.6-1.5-22.3-12.1-26.2z"/>
              <path
                  d="M393.3 844.4c-72.8-24.6-137.6-72.7-182.5-135.5-6.6-9.2-19.3-11.3-28.5-4.7-9.2 6.6-11.3 19.3-4.7 28.5 49.9 69.7 121.8 123.1 202.6 150.4 2.2 0.7 4.4 1.1 6.5 1.1 8.5 0 16.4-5.4 19.3-13.9 3.6-10.7-2.1-22.2-12.8-25.9z"/>
              <path
                  d="M842.4 704.2c-9.2-6.6-21.9-4.4-28.5 4.7-44.9 62.8-109.8 110.9-182.5 135.5-10.7 3.6-16.4 15.2-12.8 25.9 2.9 8.5 10.8 13.9 19.3 13.9 2.2 0 4.4-0.3 6.5-1.1 82-27.7 152-79.7 202.6-150.4 6.6-9.2 4.5-21.9-4.7-28.5z"/>
            </svg>

            <span class="subtitle__personal-data-text">Equipo de Trabajo</span>

            <font-awesome-layers
                class="fa-2x pointer"
                style="margin-left: 5px"
                title="Añadir al Equipo"
                @click="openModalAddEmployeeToTeam()">
              <font-awesome-icon
                  icon="circle"
                  style="color: #f7d20e"/>
              <font-awesome-icon
                  icon="plus"
                  class="fa-inverse"
                  transform="shrink-6"/>
            </font-awesome-layers>
          </div>
        </div>
        <div
            class="table"
            style="padding-top: 0"
            v-if="team && team.length > 0">
          <table>
            <thead>
            <tr>
              <th>Nombre</th>
              <th>eMail</th>
              <th>Departamento</th>
              <th>Puesto</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(teamMember) in team"
                :key="teamMember.id">
              <td>
                {{ teamMember.name }} {{ teamMember.lastName }}
              </td>
              <td>
                {{ teamMember.email }}
              </td>
              <td>
                {{ teamMember.job ? teamMember.job.name : null }}
              </td>
              <td>
                {{
                  (teamMember.job && teamMember.job.department) ?
                      teamMember.job.department.name : null
                }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <br>
        <hr>

        <!--                Botones de guardado-->
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-end">
              <div class="col-md-2">
                <routerLink
                    :to="{ name: 'employees'}"
                    class="btn btn-block btn_red"
                    style="height: 3em; line-height: 23px;">Cancelar
                </routerLink>
              </div>
              <div class="col-md-2">
                <button
                    type="submit"
                    class="btn btn-block btn_yellow"
                    style="height: 3em">
                  Guardar
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>

    </form>

    <el-dialog
        v-model="showModalCropImage"
        :title="'Crop Foto Empleado'"
        width="500"
        :before-close="closeModalCropImage">
      <div>
        <Cropper
            class="cropper"
            :src="uploadedImage ? uploadedImage : imageUrl"
            ref="cropper"
            :stencil-props="{
                           handlers: {
                              eastNorth: true,
                               westNorth: true,
                               westSouth: true,
                               eastSouth: true,
                           },
                           movable: true,
                           scalable: true,
                            aspectRatio: 1,
                        }"
            @change="changePicture"
        />
        <!--                <div class="row" @click.stop="focusOnInputCrop($event)">-->
        <div class="row justify-content-center">
          <!--                    <div class="col text-center" style="margin: 10px">-->
          <div
              class="text-center col-md-6"
              style="margin-top: 10px">
            <label
                class="pointer image d-block"
                for="imageCrop">
                        <span class="button">
                            <input
                                type="file"
                                ref="imageCrop"
                                id="imageCrop"
                                name="imageCrop"
                                @change="uploadImage($event)"
                                style="display: none"/>
                            {{ imagePreview ? "Cambiar imagen" : "Subir imagen" }}
                        </span>
            </label>
          </div>

          <!--                    <div class="col text-center" style="margin: 10px">-->
          <div
              class="text-center col-md-6"
              style="margin-top: 10px">
            <span
                class="button"
                @click="cropImage">Recortar</span>
          </div>


        </div>
      </div>

    </el-dialog>

    <!--        Modal Dar de Baja-->
    <el-dialog
        v-model="showModalUnsubscribe"
        :show-close="false"
        align-center
        width="630"
        :center="true"
        :lock-scroll="true"
        class="el-dialog"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">Baja del empleado</span>
        <img
            class="inter-icon"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
        >
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showModalUnsubscribe=false"
        >
      </div>
      <div class="mt-2 my-body">
        <div class="row item mt-1">
          <div class="col-sm-12">
            <div class="form-item date-picker">
              <label for="dismissalObservations"
                     class="w-100"
              >
                Fecha de baja
              </label>
              <el-date-picker
                  v-model="selectedDateLeave"
                  format="DD-MM-YYYY"
                  :prefix-icon="'el-icon-date'"
                  :picker-options="{firstDayOfWeek: 1}"
              />
            </div>
          </div>
        </div>
        <div class="row item mt-1">
          <div class="col-sm-12 form-item">
            <label
                for="leaveType"
                class="w-100"
            >Tipos de baja</label>
            <el-select
                v-model="employee.leaveType"
                placeholder="Tipos de baja"
                :value-key="'@id'"
            >
              <el-option
                  v-for="leaveType in leaveTypes"
                  :key="leaveType.id"
                  :label="leaveType.name"
                  :value="leaveType">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row item mt-0">
          <div class="col-sm-12 form-item">
            <label for="dismissalObservations"
                   class="w-100"
            >
              Motivo de la baja
            </label>
            <textarea
                v-model="dismissalObservations"
                label="'Motivo de la baja'"
                :placeholder="'Motivo de la baja'"
                class="w-100"
                style="width: 600px; height: 100px;"
            >
            </textarea>
          </div>
        </div>

        <div class="row item">
          <div class="col-sm-6">
            <button
                class="btn btn_red"
                @click="closeModalUnsubscribe('cancelar')">
              Cancelar
            </button>
          </div>
          <div class="col-sm-6">
            <button
                class="btn btn_blue"
                @click="closeModalUnsubscribe('aceptar')">
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </el-dialog>


<!-- Modal Ver Motivo -->

    <el-dialog
        v-model="showModalUnsubscribed"
        :show-close="false"
        width="630"
        :center="true"
        :lock-scroll="true"     
        align-center
        class="el-dialog"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3"> Baja del empleado</span>
        <img
            class="inter-icon"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
        >
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showModalUnsubscribed=false"
        >
      </div>
      <div class="mt-2 my-body">
        <div class="row item">
          <div class="col-sm-9">
            <div class="form-item date-picker">
              <label for="dismissalObservations"
                     class="w-90"
              >
                Fecha de baja
              </label>
              <el-date-picker
                  v-model="employee.dateLeave"
                  format="DD-MM-YYYY"
                  :prefix-icon="'el-icon-date'"
                  :picker-options="{firstDayOfWeek: 1}"
                  disabled
              />
            </div>
          </div>
        </div>
        <div class="row item mt-1">
          <div class="col-sm-9 form-item">
            <label
                for="leaveType"
                class="w-100"
            >Tipos de baja</label>
            <el-select
                v-model="employee.leaveType"
                placeholder="Tipos de baja"
                :value-key="'@id'"
                class="w-90"
                style="width: 550px;"
                disabled
            >
              <el-option
                  :key="employee.leaveType.id"
                  :label="employee.leaveType.name"
                  :value="employee.leaveType">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row item mt-0">
          <div class="col-sm-9 form-item">
            <label for="dismissalObservations"
                   class="w-100"
            >
              Motivo de la baja
            </label>
            <textarea
                v-model="employee.dismissalObservations"
                label="'Motivo de la baja'"
                :placeholder="'Motivo de la baja'"
                class="w-90"
                style="width: 550px; height: 100px;"
                disabled
            >
            </textarea>
          </div>
        </div>
        <div class="row item">
          <div class="col-sm-9 align-center">
            <button
                class="btn btn_red align-self-center"
                @click="closeModalUnsubscribed()">
              Volver
            </button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!--        Modal Dirección Postal-->
    <el-dialog
        v-model="showModalEmployeeAddress"
        :show-close="false"
        :theme="'secundary'"
        size="'medium'"
        width="630"
        :center="true"
        :lock-scroll="true"
        class="el-dialog"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
    >
    <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">Dirección postal</span>
        <img
            class="inter-icon"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
        >
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showModalEmployeeAddress=false"
        >
      </div>
      <div class="modal_address my-body">

        <form
            class="address-form"
            @submit.prevent="saveEmployee(employee)">
          <!--            Calle-->
          <label class="w-100">Dirección</label>
          <el-input
              v-model="employee.address"
              placeholder="Dirección de la calle"
          />
          <div class="form-item">
            <div class="row">
              <div class="col-md-6">
                <label
                    for="employeeProvinces"
                    class="w-100">Provincia</label>
                <el-select
                    v-model="province.id"
                    filterable
                    placeholder="Provincia"
                    @focus="selectProvinceFocus = true"
                    @blur="selectProvinceFocus = false"
                    @change="getLocationsByProvinceId(province.id)"
                >
                  <el-option
                      v-for="province in provinces"
                      :key="province.id"
                      :label="province.name"
                      :value="province.id">
                  </el-option>
                </el-select>
                <p
                  v-if="errorAddress.province.error"
                  class="invalid-feedback mb-0">
                <small>
                  {{ errorAddress.province.message }}
                </small>
              </p>
              </div>
              <div class="col-md-6">
                <label
                    for="employeeLocations"
                    class="w-100">Localidad</label>
                <el-select
                    v-model="location.id"
                    placeholder="Localidad"
                    filterable
                    :disabled='selectProvinceFocus'
                    @change="changeLocation(location.id)"
                >
                  <el-option
                      v-for="location in locations"
                      :key="location.id"
                      :label="location.name"
                      :value="location.id">
                  </el-option>
                </el-select>
                <p
                  v-if="errorAddress.location.error"
                  class="invalid-feedback mb-0">
                <small>
                  {{ errorAddress.location.message }}
                </small>
              </p>
              </div>
            </div>

          </div>
          <div class="form-item">
            <label class="w-100">Código postal</label>
            <el-input
                type="number"
                v-model="employee.postalCode"
                placeholder="Código postal"
            />
          </div>
        </form>
        <br/>
        <div class="row item">
          <div class="col-4">
            <button
                class="btn btn_red"
                @click="closeModalEmployeeAddress('cancelar')">
              Cancelar
            </button>
          </div>
          <div class="col-4">

          </div>
          <div class="col-4">
            <button
                class="btn btn_blue"
                :disabled='buttonSubmitAdressError'
                @click="closeModalEmployeeAddress('aceptar')">
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </el-dialog>


    <!--        Modal Personalizacion Horario-->
    <el-dialog
        v-model="showModalCustomSchedule"
        width="750"
        :center="true"
        :show-close="false"
        :lock-scroll="true"
        class="el-dialog"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
        >
        <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">Personalizar Horario</span>
        <img
            class="inter-icon"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
        >
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showModalCustomSchedule=false"
        >
      </div>
      <div class="my-body">
        <div class="row item justify-content-around">
          <div
              class="d-inline-block text-center"
              v-for="(day) in schedule.days"
              :key="day.id">
            <label
                class="form-check-label"
                for="inlineCheckbox"
                style="color: black">
              {{ trimDayText(day.day) }}
            </label>
            <br/>
            <input
                type="checkbox"
                id="inlineCheckbox"
                v-model="day.workToday"/>

          </div>
        </div>
        <hr>
        <div
            class="row item"
            style="margin: 10px">
          <label class="w-100">Presencial</label>
          <el-input
              v-model="schedule.timeEnterMorning"
              placeholder="Horario entrada"
          />
          <el-input
              v-model="schedule.timeExitMorning"
              placeholder="Horario salida"
          />
          <label style="color: black; font-size: 18px;">Teletrabajo</label>
          <el-input
              v-model="schedule.timeEnterAfternoon"
              :label="'Horario entrada'"
              :placeholder="'Horario entrada'"
          />
          <el-input
              v-model="schedule.timeExitAfternoon"
              :label="'Horario salida'"
              :placeholder="'Horario salida'"
          />

        </div>
        <hr>

        <div class="row item">
          <div class="col">
            <button
                class="btn btn-block btn_red"
                @click="closeModalCustomSchedule('cancelar')">
              Cancelar
            </button>
          </div>
          <div class="col">
            <button
                class="btn btn_blue"
                @click="closeModalCustomSchedule('aceptar')">
              Aceptar
            </button>
          </div>
        </div>

      </div>
    </el-dialog>

    <!--        Modal Añadir Miembros de Equipo-->
    <el-dialog
        v-model="showModalAddEmployeeToTeam"
        :title="'Añadir miembros al equipo'">
      <div>

        <div class="form-item row">
          <div class="col-md-9">
            <el-input
                class="search-input"
                v-model="employeeName"
                label="Nombre"
                placeholder="Nombre"
                @input="filterEmployees($event)"/>
          </div>
        </div>

        <div class="table-users">
          <div>
            <table>
              <thead>
              <tr>
                <th>Nombre</th>
                <th>Departamento</th>
                <th>Puesto</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(teamMember, index) in employeeList"
                  :key="index"
                  class="pointer"
                  :class="{ selected: isEmployeeSelected(teamMember) }"
                  @click="addEmployeeToTeam(teamMember, index)">
                <td>
                  {{ teamMember.name }} {{ teamMember.lastName }}
                </td>
                <td>
                  {{ teamMember.job ? teamMember.job.name : null }}
                </td>
                <td>
                  {{
                    teamMember.job && teamMember.job.department ? teamMember.job.department.name :
                        null
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <SharedPagination
              v-if="employeeList && totalResults > 5"
              class="pagination"
              :page="page"
              :totalResults="totalResults"
              :pageSize="itemsPerPage"
              @change="page = $event; getEmployees();"
              style="padding: 0px 0px 20px 0px;"/>
        </div>

        <div>
          <br/>
          <div class="row item">
            <div class="col">
              <button
                  class="btn btn-block btn_red"
                  @click="closeModalAddEmployeeToTeam('cancelar')">
                Cancelar
              </button>
            </div>
            <div class="col">
              <button
                  class="btn btn_blue"
                  @click="closeModalAddEmployeeToTeam('aceptar')">
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>


</template>

<script setup>
/* eslint-disable */
/* eslint-disable no-unused-vars */

// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";


import moment from 'moment';
import {Cropper} from "vue-advanced-cropper";

// Services
import EmployeeService from '@/api/services/employee.service';
import ResourceService from '@/api/services/resource.service';
import ScheduleService from '@/api/services/schedule.service';
import EmergencyContactService from '@/api/services/emergencyContact.service';
import Common from '@/api/services/common.service';


import Notification from '@/utils/plugins/NotificationService'
import {useStore} from "vuex";
import {computed, defineProps, h, onMounted, ref, shallowRef} from "vue";
import {useRoute, useRouter} from "vue-router";
import {helpers, required, requiredIf} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {ElNotification} from "element-plus";

const isEditingEmployee = ref(true)
const dismissalObservations = ref('');
const title = ref('Empleado');
const employeeName = ref(null);
const employee = ref({
  // photo: null,
  // job: null,
  // employees: null,
});
const employeeList = ref([]);
const schedule = ref({
  name: '',
  timeEnterMorning: '',
  timeExitMorning: '',
  timeEnterAfternoon: '',
  timeExitAfternoon: '',
  workHours: '',
  // mon: {type: Boolean, default: true},
  // mon: Boolean,
  mon: Boolean,
  tue: Boolean,
  wed: Boolean,
  thu: Boolean,
  fri: Boolean,
  days: [
    {day: 'mon', workToday: {type: Boolean, default: true}},
    {day: 'tue', workToday: {type: Boolean, default: true}},
    {day: 'wed', workToday: {type: Boolean, default: true}},
    {day: 'thu', workToday: {type: Boolean, default: true}},
    {day: 'fri', workToday: {type: Boolean, default: true}},
  ]
});
// const emergencyContact = ref({
//     name: '',
//     relationship: '',
//     phone: '',
// });
const emergencyContact = ref(null);
const jobs = ref([]);
const job = ref({
  // name: ''
});
const department = ref({
  // name: ''
});
const workplace = ref({
  // name: ''
});
// eslint-disable-next-line no-unused-vars
const teamMember = ref({
  name: '',
  lastName: '',
  email: '',
  job: {
    name: '',
    department: {}
  }
});
const errors = ref(null)
const leaveTypeName = ref('');
const cropper = ref(null)
const team = ref([]);
const show = ref([]);
const provinces = ref([]);
const province = ref({});
const locations = ref([]);
const location = ref({});
const selectedDayRegister = ref(null); //new Date();
const selectedDateBirthday = ref(null); //new Date();
const selectedDateLeave = ref(null); //new Date();
const submitted = ref(false);
const showModalUnsubscribe = ref(false);
const showModalUnsubscribed = ref(false);
const showModalEmployeeAddress = ref(false);
const showModalCustomSchedule = ref(false);
const showModalAddEmployeeToTeam = ref(false);
const showModalCropImage = ref(false);
const emergencyContacts = ref([{name: '', relationship: '', phone: ''}]);
const departments = ref([]);
const workplaces = ref([]);
const schedules = ref([]);
const textCalendar = ref('');
const image = ref('');
const baseUrl = ref(process.env.VUE_APP_BASE_URI);
const imagePreview = ref(null);
const dateLeave = ref(null);
const employeeImageUrl = ref(null);
const photo = ref(null);
const page = ref(1);
const itemsPerPage = ref(6);
const totalResults = ref(null);
const teamManagerName = ref('');
const isChangeSomeValuesInSchedule = ref(false);
const imageCrop = ref(null);
const leaveType = ref(null);
const leaveTypes = ref([]);
const disabledDates = ref({
  from: new Date()
});

const selectProvinceFocus = ref(false);
const buttonSubmitAdressError = ref(false);
const errorAddress = ref({
  location: {
    error: false,
    message: "Este campo es obligatorio"
  },
  province: {
    error: false,
    message: "Este campo es obligatorio"
  },
  
});

const defaultDate = ref(null);
const uploadedImage = ref(null);
const rules = computed(() => {
  let allRules = {
    name: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    lastName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    dni: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    birthday: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    email: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    emailPersonal: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    phonePersonal: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    remote: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    companyInterns: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    dateStartIntern:{
    required: helpers.withMessage("Este campo es obligatorio", requiredIf(employee.value.companyInterns))
    },
    dateEndIntern:{
       required: helpers.withMessage("Este campo es obligatorio", requiredIf(employee.value.companyInterns))
    },
    dateRegister:{
       required: helpers.withMessage("Este campo es obligatorio", requiredIf(!employee.value.companyInterns))
    },
    // ...(employee.value.companyInterns ? {
    //   dateStartIntern: {
    //     required: helpers.withMessage("Este campo es obligatorio", required),
    //   },
    //   dateEndIntern: {
    //     required: helpers.withMessage("Este campo es obligatorio", required),
    //   },
    // } : {
    //   dateRegister: {
    //     required: helpers.withMessage("Este campo es obligatorio", required),
    //   },
    // }),
  }
  // Log de las reglas aplicadas
  // console.log("allRules", allRules);
  return allRules;
});
const params = ref({
  // filter: '',
  // name: '',
  // order: '',
  page: 1,
  itemsPerPage: 10,
});
// const remote = ref(false);
const submit = ref(false);
const remoteOneButton = ref(false);
const imageUrl = ref(null);
// Referencias a los selectores excluyentes entre sí
const excluyentsDropDownsRefs = ref(['departmentsSharedDropDown', 'jobsSharedDropDown', 'workplacesSharedDropDown', 'leaveTypesSharedDropDown', 'employeeProvincesSharedDropDown', 'employeeLocationsSharedDropDown']);

const route = useRoute();
const router = useRouter();
const store = useStore();
const user = computed(() => store.state.user);
const v$ = useVuelidate(rules, employee);
let v = v$.value;
const props = defineProps({
  profile: {type: Boolean, default: false},
})
onMounted(() => {
  if (ifIsInArray(user.value.roles, 'ROLE_ADMIN')) {
    if (props.profile) {
      getEmployee(store.state.employee.id)
    } else if (route.params.id && route.params.id === 'new') {
      title.value = 'Nuevo Empleado'
      employee.value = {};
      employee.value.dateRegister = null;
      employee.value.remote = false;
      employee.value.companyInterns = false;
      isEditingEmployee.value = false;
      employee.value.dateStartIntern = null;
      employee.value.dateEndIntern = null;
    } else {
      getEmployee(route.params.id)
    }
    getJobs();
    getDepartments();
    getWorkplaces();
    getProvinces();
    getEmployees();
    if (employee.value.province) {
      getLocationsByProvinceId(employee.value.province);
    }
  }

})

const changePicture = ({coordinates, canvas}) => {
  cropper.value = {
    coordinates,
    canvas
  }
}
// Elimina hijos de un nodo
const removeElement = () => {

  setTimeout(() => {
    const list = document.getElementsByClassName("input-group-text");
    for (let i = 0; i <= list.length; i++) {
      if (list[i] && list[i].childNodes) {
        list[i].childNodes.forEach((item, index) => {
          if (index !== 0) {
            list[i].removeChild(list[i].childNodes[index]);
          }
        });
      }
    }
  }, 0)

}
const defaultSize = () => {
  return {
    width: 400,
    height: 400,
  }
}
const pixelsRestriction = ({minWidth, minHeight, maxWidth, maxHeight}) => {
  return {
    minWidth: minWidth,
    minHeight: minHeight,
    maxWidth: maxWidth,
    maxHeight: maxHeight,
  }
}
const uploadImage = (event) => {
  // console.log("uploadImage", event);
  // Hace referencia al DOM input element
  const input = event.target;
  // Asegura tener un archivo antes de leerlo
  if (input.files && input.files[0]) {
    // Crea un nuevo FileReader para leer la imagen y la convierte a formato base64
    const reader = new FileReader();
    // Define una funcion callback para que se ejecute cuando FileReader finalice su trabajo
    reader.onload = (e) => {
      // Nota: la función de flecha utilizada aqui, de modo que "imageData" se refiere al componente
      // imageData de Vue.
      // Leemos la imagen como base64 y la establecemos en imageData
      uploadedImage.value = e.target.result;
    }
    // Se inicia el trabajo de lectura, leyendo el archivo como un url datos (en formato base64)
    reader.readAsDataURL(input.files[0]);
  }
}
const cropImage = async () => {


  let image = cropper.value.canvas.toDataURL(
      "image/jpeg"
  );
  imagePreview.value = image;
  image = dataURItoBlob(image);
  await EmployeeService.uploadImageFile(image)
      .then((res) => {
        let id = employee.value.photo && employee.value.photo.id ? employee.value.photo.id : null;

        if (employee.value.id) {
          employee.value.photo = res.data['@id'];
          updateEmployee(id);
        } else {
          imagePreview.value = res.data['contentUrl'];
          employee.value.photo = res.data['@id'];
        }

      })
      .catch(() => {
        Notification.addNotification('Error al cargar la imagen', 'error', 6000)
      });

  closeModalCropImage();
}

const dataURItoBlob = (dataURI) => {
  // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type: mimeString});
}
const isEmployeeSelected = (itemSelected) => {
  return team.value.some(member => member.id === itemSelected.id);
}
const addEmployeeToTeam = (teamMember) => {
  let existMember = team.value.findIndex(member => member.id === teamMember.id);
  if (existMember === -1) {
    team.value.push(teamMember);
  } else {
    team.value.splice(existMember, 1);
  }
}

const filterEmployees = (name) => {
  if (name) {
    name = name.toLowerCase();
  }
  params.value = {
    ...params.value,
    name: name,
  };
  getEmployees();

}
const getEmployees = (filters) => {
  params.value = {
    ...params.value,
    filter: filters,
    // query: {name: searchName ? searchName : ''},
    // name: searchName ? searchName : '',
    'order[name]': 'ASC',
    // offset: (page * pageSize) - pageSize,
    // size: pageSize,
    page: page,
    itemsPerPage: itemsPerPage
  };
  EmployeeService.getEmployees(params.value)
      .then((res) => {
        employeeList.value = res.data['hydra:member'];
        totalResults.value = res.data['hydra:totalItems'];

        if (employee.value.id && team) {
          let removeEmployee = employeeList.value.findIndex(emp => emp.id === employee.value.id)
          if (removeEmployee >= 0) {
            employeeList.value.splice(removeEmployee, 1);
            totalResults.value = totalResults.value - 1;
          }

          if (employeeList.value && team.value.length > 0) {
            team.value.forEach((item) => {
              let existMember = employeeList.value.findIndex(emp => emp.id === item.id);
              if (existMember >= 0) {
                employeeList.value.splice(existMember, 1);
              }
            })
            employeeList.value = team.value.concat(employeeList.value);
          }
        }

      })
}
// handleFileUpload(event) {
//     console.log("handleFileUpload")
//     if (employee.id && employee.photo) {
//         employee.photo = null;
//         saveEmployee(employee);
//     }
//     image = $refs.employeeImage.files[0];
//     imagePreview = URL.createObjectURL(event.target.files[0]);
// },
const saveImage = async (employee) => {
  if (image.value.size > 1536 * 1024) {
    Notification.addNotification('La imagen cargada ocupa más de 1,5MB', 'warning', 6000);
    return
  }
  // if (image['type'] !== 'image/jpeg'
  //     || image['type'] !== 'image/jpeg'
  //     || image['type'] !== 'image/jpg'
  //     || image['type'] !== 'image/png') {
  //         Notification.addNotification('La imagen cargada no corresponde a los formatos permitidos', 'warning', 6000);
  //     return false;
  // }
  // let rest = '';
  await EmployeeService.uploadImageFile(employee.id, image)
      .then((res) => {

        employee.photo = res.data['@id'];

        Notification.addNotification('Imagen cargada correctamente', 'success', 6000)
        if (employee.id) {
          updateEmployee()
        } else {
          createEmployee();
        }
        // saveEmployee(employee);
      })
      .catch(() => {
        Notification.addNotification('Error al cargar la imagen', 'error', 6000)
      });
}

const removeImage = () => {
  employee.value.photo = null;
  imagePreview.value = null;
  image.value = '';
  saveEmployee(employee.value);
}

const getImageUrl = (id) => {
  EmployeeService.getImageEmployee(id)
      .then((res) => {
        imagePreview.value = res.data['contentUrl'];
        while (imagePreview.value.charAt(0) === '/') {
          imagePreview.value = imagePreview.value.substr(1);
        }
        employeeImageUrl.value = imagePreview;
      });

}
const focusOnInputCrop = (event) => {
  if (event) {
    setTimeout(() => {
      document.querySelector('#imageCrop').focus();
      imageCrop.value.focus()
    }, 1000)
  } else {
    setTimeout(() => {
      document.querySelector('#imageCrop').focus();
      imageCrop.value.focus()
    }, 1000)
  }

}
// focusOnInput(event) {
//     setTimeout(() => {
//         document.querySelector('#employeeImage').focus();
//         $refs.employeeImage.focus()
//     }, 1000)
// },
const formatDateFilter = (value) => {
  if (value) {
    return moment(value).format('YYYY-MM-DD');
  } else {
    return null;
  }

}

const formatDateLocale = (value, loc) => {
  if (loc) {
    loc = 'es';
  }
  moment.updateLocale('es', {
    monthsShort: [
      "Ene", "Feb", "Mar", "Abr", "May", "Jun",
      "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ]
  });
  if (value) {
    return moment(value).local(loc).format('DD MMM YYYY');
  } else {
    return null;
  }

}

const openModalCropImage = () => {
  console.log("openModalCropImage", baseUrl.value + imagePreview.value)

  // imageUrl.value = baseUrl.value + imagePreview.value;
  imageUrl.value = imagePreview.value ? "https://connectuspre.undanet.com/backend" + imagePreview.value : null;
  // imageUrl.value = "https://connectuspre.undanet.com/backend/documents/undanitas/producto11-6601d44b1b617345207743.jpg";
  showModalCropImage.value = true;

}
const closeModalCropImage = () => {
  showModalCropImage.value = !showModalCropImage.value;
}
const openModalUnsubscribe = () => {
  selectedDateLeave.value = new Date();
  showModalUnsubscribe.value = true;
  dismissalObservations.value = employee.value.dismissalObservations;
  getLeaveTypes();
}
const closeModalUnsubscribe = (str) => {
  switch (str) {
    case 'cancelar':
      selectedDateLeave.value = null;
      leaveType.value = null;
      employee.value.leaveType = null;
      dismissalObservations.value = null;
      break;
    case 'aceptar':
      employee.value.dateLeave = selectedDateLeave;
      dateLeave.value = formatDateLocale(selectedDateLeave, 'es');
      employee.value.dismissalObservations = dismissalObservations.value;
      employee.value.active = false;
      saveEmployee(employee.value);
      break;
    default:
      selectedDateLeave.value = null;
      leaveType.value = null;
      dismissalObservations.value = null;
      employee.value.leaveType = null;
      break;

  }
  showModalUnsubscribe.value = !showModalUnsubscribe.value;
}
const openModalUnsubscribed = () => {
  showModalUnsubscribed.value = true;  
}
const closeModalUnsubscribed = () => {
  showModalUnsubscribed.value = !showModalUnsubscribed.value;
}
const openModalEmployeeAddress = () => {
  if (employee.value) {
    if (employee.value.province) {
      getLocationsByProvinceId(employee.value.province.id);
    }
    if(employee.value.location){
      //changeLocation(employee.value.location.id);
    }
  }
  showModalEmployeeAddress.value = true;
}
const closeModalEmployeeAddress = (str) => {
  switch (str) {
    case 'cancelar':
      province.value = {};
      location.value = {};
      break;
    case 'aceptar':
      if(!location.value.id){
        checkCloseModalEmployeeAdress();
        return;
      }
      
      break;
    default:
      break;
  }
  showModalEmployeeAddress.value = !showModalEmployeeAddress.value;
}
const openModalCustomSchedule = () => {

  showModalCustomSchedule.value = true;
  schedule.value = setEmployeeSchedule(schedule.value);
}
const closeModalCustomSchedule = (str) => {
  switch (str) {
    case 'cancelar':
      showModalCustomSchedule.value = !showModalCustomSchedule.value;

      break;
    case 'aceptar':
      showModalCustomSchedule.value = !showModalCustomSchedule.value;
      if (schedule.value.days) {
        schedule.value.days.forEach((day) => {
          schedule.value[day.day] = day.workToday;
        });
      } else {
        schedule.value = setEmployeeSchedule(schedule.value);
        schedule.value.days.forEach((day) => {
          schedule.value[day.day] = day.workToday;
        });
      }

      if (schedule.value.timeEnterMorning === "") {
        schedule.value.timeEnterMorning = null;
      }
      if (schedule.value.timeExitMorning === "") {
        schedule.value.timeExitMorning = null;
      }
      if (schedule.value.timeEnterAfternoon === "") {
        schedule.value.timeEnterAfternoon = null;
      }
      if (schedule.value.timeExitAfternoon === "") {
        schedule.value.timeExitAfternoon = null;
      }

      schedule.value = setEmployeeSchedule(schedule.value);
      textCalendar.value = setTextWorkCalendar(schedule.value);

      delete schedule.value.days;
      saveSchedule(schedule.value);

      break;
    default:
      showModalCustomSchedule.value = !showModalCustomSchedule.value;
      break;

  }

}
const openModalAddEmployeeToTeam = () => {
  showModalAddEmployeeToTeam.value = true;
}
const closeModalAddEmployeeToTeam = (str) => {
  switch (str) {
    case 'cancelar':
      showModalAddEmployeeToTeam.value = !showModalAddEmployeeToTeam.value;
      team.value = [];
      Object.assign(team, employee.value.employees);
      break;
    case 'aceptar':
      showModalAddEmployeeToTeam.value = !showModalAddEmployeeToTeam.value;
      employee.value.employees = team;
      break;
    default:
      showModalAddEmployeeToTeam.value = !showModalAddEmployeeToTeam.value;
      team.value = [];
      Object.assign(team.value, employee.value.employees);
      break;
  }
}
const getJobs = (searchQuery = '') => {
  // const params = {query: {name: searchQuery}};
  ResourceService.getJobs()
      .then((res) => {
        jobs.value = res.data['hydra:member'];
      })
}
const getJobsFromDepartment = (department) => {
  job.value = {};
  jobs.value = [];
  // const params = {department: department['@id']};
  const params = { 'department.id': department.id };
  ResourceService.getJobs(params)
      .then((res) => {
        jobs.value = res.data['hydra:member'];
        employee.value.job = jobs.value[0];
      })
}
const getDepartments = (searchQuery = '') => {
  // const params = {name: searchQuery};
  ResourceService.getDepartments(params.value)
      .then((res) => {
        departments.value = res.data['hydra:member'];
      })
}
const getWorkplaces = (searchQuery = '') => {
  // const params = {name: searchQuery};
  ResourceService.getWorkplaces(params.value)
      .then((res) => {
        workplaces.value = res.data['hydra:member'];
      })
}
const getSchedules = (searchQuery = '') => {
  const params = {query: {name: searchQuery}};
  ScheduleService.getSchedules(params)
      .then((res) => {
        schedules.value = res.data['hydra:member'];
      });
  // return schedule;
}
// getScheduleGeneral() {
//     const params = {query: {name: 'general'}};
//     ScheduleService.getSchedules(params)
//         .then((res) => {
//             let schedule = res.data['hydra:member'][0];
//             schedule = setEmployeeSchedule(schedule);
//         });
// },
const getLocationsByProvince = (searchQuery = '') => {

  params.value = {
    ...params.value,
    'province.name': searchQuery.name,
  };

  ResourceService.getLocations(params.value)
      .then((res) => {
        locations.value = res.data['hydra:member'];
      })
}
const getLocations = (searchQuery = '') => {
  if (province.value && province.value.name) {
    params.value = {
      ...params.value,
      'province.name': province.value.name
    };
  } else {
    if (employee.value.province && employee.value.province.name) {
      params.value = {
        ...params.value,
        'province.name': employee.value.province.name
      };
    }
  }
  params.value = {
    ...params.value,
    'name': searchQuery
  };
  ResourceService.getLocations(params.value)
      .then((res) => {
        locations.value = res.data['hydra:member'];
      })
}

const getLocationsByProvinceId = (id) => {  
  params.value = {
    page: 1,
  itemsPerPage: 99999,
  };
  employee.value.province = provinces.value.find(x=>x.id===id);
  errorAddress.value.province.error = false;
  if(employee.value.postalCode){
    if(employee.value.province.id != employee.value.postalCode.toString().substr(0,2)){
        employee.value.postalCode = employee.value.province.id;
    }
  }else{
      employee.value.postalCode = employee.value.province.id;
  }

  ResourceService.getLocationsByProvinceId(params.value, id)
      .then((res) => {
        locations.value = res.data['hydra:member'];
        if(employee.value.location){
          changeLocation(employee.value.location.id)
        }
      })
}

const changeLocation = (id) => {
  let newLocation = locations.value.find(x=> x.id===id)  
  if(newLocation){
    employee.value.location = newLocation;
    buttonSubmitAdressError.value = false;
    errorAddress.value.location.error = false;
  }else{
    location.value = {};
    employee.value.location = {};
  }
}

const checkCloseModalEmployeeAdress = () => {  
  if(!location.value.id){
    errorAddress.value.location={
      error: true,
      message: "Este campo es obligatorio"
    }
  if(!province.value.id){
    errorAddress.value.province={
      error: true,
      message: "Este campo es obligatorio"
    } 
  }
  buttonSubmitAdressError.value = true;
}
}

const getProvinces = (searchQuery = '') => {
  // const params = {name: searchQuery};
  ResourceService.getProvinces(params.value)
      .then((res) => {
        provinces.value = res.data['hydra:member'];
      })
}
const getLeaveTypes = (searchQuery = '') => {
  // const params = {query: {name: searchQuery}};
  ResourceService.getLeaveTypes(params.value)
      .then((res) => {
        leaveTypes.value = res.data['hydra:member'];
      })
}

const updateEmployedFromData = () => {
  const {
    photo,
    workplace,
    emergencyContacts,
    job,
    teamManager,
    location,
    schedule,
    province,
    leaveType,
    employees,
    ...rest
  }
      = employee.value;
  return {
    ...rest, // Any other field
    photo: typeof photo === 'object' && photo?.['@id'] ? photo?.['@id'] : photo,
    workplace: workplace?.['@id'],
    emergencyContacts: emergencyContacts?.map(obj => obj['@id']),
    job: job?.['@id'],
    teamManager: teamManager?.['@id'],
    location: location?.['@id'],
    schedule: schedule?.['@id'],
    province: province?.['@id'],
    leaveType: leaveType?.['@id'],
    employees: employees?.map(obj => obj['@id']),
  }
}

const getEmployee = (id) => {
  store.state.loading = true;
  EmployeeService.getEmployee(id)
      .then((res) => {
        employee.value = res.data && res.data.id ? res.data : route.push('/employees');
        title.value = `Editar usuario: ${employee.value.name && employee.value.lastName ? `${employee.value.name} ${employee.value.lastName}` : employee.value.name ? employee.value.name : employee.value.lastName}`;

        if (employee.value.dateLeave) {
          dateLeave.value = formatDateLocale(employee.value.dateLeave, 'es')
        }

        textCalendar.value = setTextWorkCalendar(employee.value.schedule);

        if (employee.value.schedule) {
          schedule.value = setEmployeeSchedule(employee.value.schedule);
        }


        if (employee.value.photo) {
          imagePreview.value = employee.value.photo.contentUrl
          employeeImageUrl.value = employee.value.photo.contentUrl

        }
        if (employee.value.postalCode) {
          employee.value.postalCode = parseInt(employee.value.postalCode);
        }
        if (employee.value.job) {
          job.value = employee.value.job;
        }

        if (employee.value.job && employee.value.job.department) {
          department.value = employee.value.job.department;
        }

        if (employee.value.workplace) {
          workplace.value = employee.value.workplace;
        }

        if (employee.value.leaveType) {
          leaveTypeName.value = employee.value.leaveType.name;
          leaveType.value = {
            ...employee.value.leaveType
          };
        }

        if(employee.value.province){
          province.value = employee.value.province;
        }

        if(employee.value.location){
          location.value = employee.value.location;
        }

        teamManagerName.value = employee.value.teamManager ? (employee.value.teamManager.name + ' ' + employee.value.teamManager.lastName) : '';

        emergencyContacts.value = (employee.value.emergencyContacts && employee.value.emergencyContacts.length > 0) ? employee.value.emergencyContacts : emergencyContacts.value;

        // team = (employee.employees && employee.employees.length > 0) ? Object.assign(team, employee.employees) : team;

        team.value = Object.assign(team.value, employee.value.employees);
        store.dispatch('setLoading', false);
      })
      .catch(() => {
        Notification.addNotification('Error al obtener la información del empleado', 'error', 6000);
        store.dispatch('setLoading', false);
      });

}
const updateEmployee = (id = null) => {
  store.dispatch('setLoading', true);

  EmployeeService.updateEmployee(updateEmployedFromData(), employee.value.id)
      .then(() => {
        getEmployee(employee.value.id);
        Notification.addNotification('Empleado actualizado correctamente', 'success', 6000);
        store.dispatch('setLoading', false);
      })
      .catch(() => {
        Notification.addNotification('Error al actualizar el empleado', 'error', 6000);
        store.dispatch('setLoading', false);
      });
}
const createEmployee = () => {
  store.dispatch('setLoading', true);
  employee.value.active = true;
  if (employee.value.companyInterns) {
    delete employee.dateRegister
    // employee.value.dateRegister = null;
  }
  EmployeeService.createEmployee(updateEmployedFromData())
      .then((res) => {
        router.push('/employees');
        Notification.addNotification('Empleado creado correctamente', 'success', 6000);
        store.dispatch('setLoading', false);
        return res;
      })
      .catch(() => {
        Notification.addNotification('Error al crear el empleado', 'error', 6000);
        store.dispatch('setLoading', false);
      })
}
const saveEmployee = (edit = false) => {
  v$.value.$reset();
  v$.value.$validate();
  v = v$.value;

  if (v$.value.$invalid) {
    ElNotification({
      type: 'error',
      message: 'Error al crear el empleado. Revise los campos marcados en rojo',
      duration: 6000
    })
  } else {
    if (edit) {
      updateEmployee();

    } else {
      createEmployee();

    }

  }

  /* setTimeout(() => {
 $validator.validate()
       .then(() => {
         if (errors.value.items.length > 0) {
           Notification.addNotification('No se pudo completar el proceso, ha de rellenar todos los campos marcados y requeridos', 'warning', 6000);
           return;
         }

         if (employee.id) {

           employee.emergencyContacts = [];

           if (emergencyContacts.value) {
             emergencyContacts.value.forEach((itemEmergencyContact) => {
               if (itemEmergencyContact.id) {
                 setTimeout(() => {
                   updateEmergencyContact(itemEmergencyContact);
                 }, 1000);
                 employee.emergencyContacts.push(itemEmergencyContact['@id']);
               } else {
                 const saveEmergencyContact = {
                   ...itemEmergencyContact,
                   'employee': employee['@id'],
                 };
                 setTimeout(() => {
                   createEmergencyContact(saveEmergencyContact)
                 }, 1000);
               }
             });
           }

           if (employee.employees) {
             let team = employee.employees;
             employee.employees = [];
             team.forEach((employeeInTeam) => {
               if (employeeInTeam.id) {
                 employee.employees.push(employeeInTeam['@id']);
               }
             })
           }

           if (employee.teamManager) {
             employee.teamManager = employee.teamManager['@id'];
           }

           if (employee.leaveType) {
             employee.leaveType = employee.leaveType['@id'];
           }


           if (image.value) {
             saveImage(employee);
           } else {
             updateEmployee(employee);
           }

         } else {
           if (image.value) {
             saveImage(employee);
           } else {
             createEmployee(employee);
           }

         }
       //  $validator.reset();
    //  })
 }, 100);*/
}
// checkErrors(errorName, errorsBag)
const addEmergencyContact = () => {
  /* const results = Promise.all([
     $validator.validate('emergencyContactName'),
     $validator.validate('emergencyContactPhone'),
     $validator.validate('emergencyContactRelationship')
   ]);
   const areValid = (await results).every(isValid => isValid);
   if (!areValid) {
     Notification.addNotification('No se pudo completar el proceso, ha de rellenar todos los campos marcados y requeridos', 'warning', 6000);
     return;
   } else {*/
  if (emergencyContacts.value && emergencyContacts.value.length < 3) {
    emergencyContacts.value.splice(0, 0, [{name: '', relationship: '', phone: ''}]);
  }
  // emergencyContact = {};
  // emergencyContact = {};
  //  }
}
const createEmergencyContact = (emergencyContact) => {
  store.state.loading = true;
  employee.value.active = true;
  EmergencyContactService.createEmergencyContact(emergencyContact)
      .then(() => {
        Notification.addNotification('Contacto de emergencia creado correctamente', 'success', 6000);
        store.state.loading = false;
      })
      .catch(() => {
        Notification.addNotification('Error al crear el contacto de emergencia', 'error', 6000);
        store.state.loading = false;
      })
}
const updateEmergencyContact = (emergencyContact) => {
  store.state.loading = true;
  EmergencyContactService.updateEmergencyContact(emergencyContact, emergencyContact.id).then(() => {
    Notification.addNotification('Contacto de emergencia actualizado correctamente', 'success', 6000);
    store.state.loading = false;
  })
      .catch(() => {
        Notification.addNotification('Error al actualizar el contacto de emergencia', 'error', 6000);
        store.state.loading = false;
      });
}
const removeEmergencyContact = (emergencyContact, index) => {
  if (emergencyContact.id) {
    emergencyContacts.value.splice(index, 1);
    EmergencyContactService.deleteEmergencyContact(emergencyContact.id)
        .then(() => {
          Notification.addNotification('Contacto de emergencia eliminado correctamente.', 'success', 6000);
        })
        .catch(() => {
          Notification.addNotification('Error al intentar eliminar el contacto de emergencia seleccionado.', 'error', 6000);
        })
  } else {
    emergencyContacts.value.splice(index, 1);
  }

}
const saveSchedule = async (schedule) => {
  //  let aSchedule = {};
  if (employee.value && employee.value.id) {
    if (schedule.id) {
      await updateSchedule(schedule);
      saveEmployee(employee.value);
    } else {
      await createSchedule(schedule);
      saveEmployee(employee.value);
    }
  }
}
const createSchedule = (schedule) => {
  store.state.loading = true;
  // employee.active = true;
  ScheduleService.createSchedule(schedule)
      .then((res) => {
        Notification.addNotification('Calendario creado correctamente', 'success', 6000);
        store.state.loading = false;
        schedule.value = res.data;
        return res;
      })
      .catch(() => {
        Notification.addNotification('Error al crear el calendario', 'error', 6000);
        store.state.loading = false;
      })
}
const updateSchedule = (schedule) => {
  store.state.loading = true;

  ScheduleService.updateSchedule(schedule, schedule.id)
      .then((res) => {
        Notification.addNotification('Calendario actualizado correctamente', 'success', 6000);
        store.state.loading = false;
        schedule.value = res.data;
        return res;

      })
      .catch(() => {
        Notification.addNotification('Error al actualizar el calendario', 'error', 6000);
        store.state.loading = false;
      });
}

const setEmployeeSchedule = (aSchedule) => {

  if (aSchedule) {
    let schedule = {
      ...aSchedule
    }

    if (schedule.name === 'general') {
      delete schedule['@id'];
      delete schedule['@type'];
      delete schedule['id'];
      schedule.name = employee.value.name;
    }

    let arrayDays = [];
    arrayDays.push({'day': 'mon', 'workToday': schedule.mon});
    arrayDays.push({'day': 'tue', 'workToday': schedule.tue});
    arrayDays.push({'day': 'wed', 'workToday': schedule.wed});
    arrayDays.push({'day': 'thu', 'workToday': schedule.thu});
    arrayDays.push({'day': 'fri', 'workToday': schedule.fri});

    schedule.days = arrayDays;

    return schedule;

  } else {
    return '';
  }
}
const setTextWorkCalendar = (aSchedule) => {

  if (aSchedule) {
    let schedule = {
      ...aSchedule
    };


    let text = '';
    const arrayDays = [];

    // daysText += schedule.mon ? 'Lun' : schedule.tue ? 'Mar' : schedule.wed ? 'Mie' :  schedule.thu ? 'Jue' : schedule.fri ? 'Vie' : '';

    schedule.mon ? arrayDays.push('Lun') : '';
    schedule.tue ? arrayDays.push('Mar') : '';
    schedule.wed ? arrayDays.push('Mie') : '';
    schedule.thu ? arrayDays.push('Jue') : '';
    schedule.fri ? arrayDays.push('Vie') : '';

    const firstDay = arrayDays[0];
    const lastDay = arrayDays[arrayDays.length - 1];


    text = firstDay + '-' + lastDay;
    // schedules.forEach(element => {
    //
    //     if (!timeText) {
    //         daysText = element.day;
    //         arrayDays.push(element.day);
    //         if (element.timeEnterMorning && element.timeExitMorning) {
    //             timeText = element.timeEnterMorning ? moment(element.timeEnterMorning).format('HH:mm') : '';
    //             timeText += '-';
    //             timeText += element.timeExitMorning ? moment(element.timeExitMorning).format('HH:mm') : '';
    //         }
    //         if (element.timeEnterAfternoon && element.timeExitAfternoon) {
    //             timeText += ', de ';
    //             timeText += element.timeEnterAfternoon ? moment(element.timeEnterAfternoon).format('HH:mm') : '';
    //             timeText += '-';
    //             timeText += element.timeExitAfternoon ? moment(element.timeExitAfternoon).format('HH:mm') : '';
    //         }
    //     } else {
    //         var nextTimeText = '';
    //         arrayDays.push(element.day);
    //         if (element.timeEnterMorning && element.timeExitMorning) {
    //             nextTimeText = element.timeEnterMorning ? moment(element.timeEnterMorning).format('HH:mm') : '';
    //             nextTimeText += '-';
    //             nextTimeText += element.timeExitMorning ? moment(element.timeExitMorning).format('HH:mm') : '';
    //         }
    //         if (element.timeEnterAfternoon && element.timeExitAfternoon) {
    //             nextTimeText += ', de ';
    //             nextTimeText += element.timeEnterAfternoon ? moment(element.timeEnterAfternoon).format('HH:mm') : '';
    //             nextTimeText += '-';
    //             nextTimeText += element.timeExitAfternoon ? moment(element.timeExitAfternoon).format('HH:mm') : '';
    //         }
    //         if (nextTimeText !== timeText) {
    //             changeHour = true;
    //             arrayDays.pop();
    //             var lastDay = arrayDays[arrayDays.length - 1];
    //             text += daysText.substr(0, 3) + '-' + lastDay.substr(0, 3) + ' de ' + timeText;
    //             timeText = nextTimeText;
    //             daysText = element.day;
    //         } else {
    //             changeHour = false;
    //         }
    //
    //     }
    // });

    // if (changeHour) {
    //     text = text + ' ' + daysText.substr(0, 3) + ' de ' + timeText;
    // } else {
    //     text = daysText.substr(0, 3) + '-' + arrayDays[arrayDays.length - 1].substr(0, 3) + ' de ' + timeText;
    // }

    text += ', ';
    text += (schedule.timeEnterMorning && schedule.timeExitMorning) ? 'de ' + schedule.timeEnterMorning + '-' + schedule.timeExitMorning : '';
    text += (schedule.timeEnterMorning && schedule.timeExitMorning && schedule.timeEnterAfternoon && schedule.timeExitAfternoon) ? ', de ' : '';
    text += (schedule.timeEnterAfternoon && schedule.timeExitAfternoon) ? schedule.timeEnterAfternoon + '-' + schedule.timeExitAfternoon : '';

    return text;

  }

  return "Sin Horario";
}
const ifIsInArray = (userRoles, role) => {
  return Common.ifIsInArray(userRoles, role);
}
/* Evento que se ejecuta cuando un selector se despliega */
// eslint-disable-next-line no-unused-vars
const sharedDropdownOpened = (openedDropdownRef) => {
  // Ordena el cierre del resto de selectores excluyentes...
  for (let i = 0; i < excluyentsDropDownsRefs.value.length; ++i) {
    if (openedDropdownRef.value != excluyentsDropDownsRefs.value[i]) {
      excluyentsDropDownsRefs.value[i].closeDropdown();
    }
  }
}
const formatDate = (value) => {
  return moment(value).format('DD/MM/YYYY');
}
const formatCompleteDate = (value) => {
  moment.locale('es');
  return moment(value).format('DD MMMM YYYY');
}
const trimDayText = (value) => {

  let text = '';
  switch (value) {
    case 'monday':
    case 'mon':
      text = 'Lun';
      break;
    case 'tuesday':
    case 'tue':
      text = 'Mar';
      break;
    case 'wednesday':
    case 'wed':
      text = 'Mie';
      break;
    case 'thursday':
    case 'thu':
      text = 'Jue';
      break;
    case 'friday':
    case 'fri':
      text = 'Vie';
      break;
    default:
      break;
  }
  return text;
}

const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})

const disabledDate = (time) => {
  if (employee.value.dateStartIntern) {
    defaultDate.value = employee.value.dateStartIntern;
    let date = new Date(employee.value.dateStartIntern);
    return time.getTime() <= date.setDate(date.getDate() - 1);
  } else {
    return time.getTime() <= Date.now();
  }
}

const changeDate = () =>{
  if (employee.value.dateStartIntern > employee.value.dateEndIntern) {
    employee.value.dateEndIntern = null;
  }
}

</script>

<style lang="scss">
.vdp-datepicker__calendar-button {
  .input-group-text {
    width: 24px;
  }

}

.vdp-datepicker__clear-button {
  .input-group-text {
    i {
      span {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
      }
    }

  }
}

</style>


<style
    lang="scss"
    scoped>

.radio-larg {
  width: 25px;
  height: 25px;
}

.subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .subtitle__search-icon {
    fill: $bg-blue;
    width: 45px;
    margin-right: 10px;
  }

  .subtitle__search-text {
    color: $bg-blue;
    letter-spacing: -0.05em;
    font-size: 2.5em;
    font-weight: bolder;
  }
}

.subtitle__personal-data {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;

  .subtitle__personal-data-icon {
    fill: $bg-blue;
    width: 35px;
    margin-right: 10px;
  }

  .subtitle__personal-data-text {
    color: $bg-blue;
    letter-spacing: -0.05em;
    font-size: 2em;
    font-weight: bolder;
    text-transform: uppercase;
  }
}

.el-date-editor {
  width: 32em;
}

.birthday.el-date-editor {
  width: 33em;
}

.employee-item {
  padding: 0px 0px 50px 0px;

  .form-item {
    label.image {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      align-self: center;
    }
  }

  img {
    /*min-width: ;*/
    width: 200px;
    height: 200px;
    /*width: 90%;*/
    margin: auto auto auto 0;
    display: block;
    border-radius: 50%;
  }

  .undanita {
    background-color: white;
    border-radius: 20px;
    padding: 10px 10px 30px 10px;
  }

  label.checkbox-label input[type=checkbox] {
    position: relative;
    vertical-align: middle;
    bottom: 1px;
  }

  .label-employee {
    color: #434343;
    float: left;
    display: block;
    white-space: nowrap;

  }

  .h3-employee {
    color: #434343;
    float: left;
  }


}

.table-users {
  /*padding: 40px;*/
  padding-top: 10px;
  text-align-all: left;

  table {
    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px;
      padding-left: 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {
      .selected {
        background-color: rgba(0, 0, 0, .06);

        td {
          background-color: rgba(0, 0, 0, .06);
        }
      }

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        text-align: left;

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }
    }
  }
}

.modal_address {
  h2 {
    color: black;
  }

  li {
    color: black;
  }

  span {
    color: black;
  }
}

.upload-example-cropper {
  border: solid 1px #EEE;
  height: 300px;
  width: 100%;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  background: #3fb37f;
  cursor: pointer;
  transition: background 0.5s;
  display: block;
  border: solid 1px #EEE;
  border-radius: .25rem;
}

.button:hover {
  background: #38d890;
}

.button input {
  display: none;
}

.personal-data, .postal-data, .company-data, .contact-data, .team-data {
  margin-bottom: 3em;
}

.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body{
    margin-right: 5%;
  }

}



.btn_unsubscribe{
  max-width: 200px; 
  height: 42px;
}



.error_date{
  margin-top: 10px;
}

</style>
