<template>
  <div>
    <HeaderTitle title="Comunicación"/>

    <TicketForm
        v-if="ticket"
        action="update"
        :ticket="ticket"
        :isSubmitting="isSubmitting"
        @saveData="updateTicket"
        @get-ticket="getTicket"
    />
  </div>
</template>

<script setup>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";

import TicketService from "@/api/services/ticket.service";
import TicketForm from "@/views/internal-tickets/InternalTicketForm.vue";
import router from "@/app/router/router";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {ElNotification} from "element-plus";

const route = useRoute()
const ticket = ref(null)
const isSubmitting = ref(false)

onMounted(async () => {
  await getTicket();
})


const getTicket = async () => {
  const id = route.params.id;
  await TicketService.getTicket(id).then(response => {
    if (response && response.status === 200) {
      ticket.value = response.data;
      if (typeof ticket.value.department === 'object') {
        ticket.value.department = ticket.value.department['@id']
      }
    }
  });

}

const updateTicket = (form) => {
  if (isSubmitting.value) {
    return;
  }
  isSubmitting.value = true;
  const formTicket = JSON.parse(JSON.stringify(form));
  formTicket.ticketType = form.ticketType ? typeof form.ticketType === 'object' ? form.ticketType['@id'] : form.ticketType : null;
  formTicket.ticketPriority = form.ticketPriority && typeof form.ticketPriority === 'object' ? form.ticketPriority['@id'] : form.ticketPriority;
  formTicket.department = form.department ? form.department['@id'] : null;
  formTicket.project = form.project ? typeof form.project === 'object' ? form.project['@id'] : form.project : null;
  formTicket.internal = true

  if (formTicket.client == 12) {
    formTicket.requestingEmployee = formTicket.requestingUser ? typeof formTicket.requestingUser === 'object' ? formTicket.requestingUser['@id'] : formTicket.requestingUser : null;
    formTicket.affectedEmployee = formTicket.affectedUser ? typeof formTicket.project === 'object' ? formTicket.affectedUser['@id'] : formTicket.affectedUser : null;
    delete (formTicket.requestingUser)
    delete (formTicket.affectedUser)
  } else {
    formTicket.requestingUser = formTicket.requestingUser ? typeof formTicket.requestingUser === 'object' ? formTicket.requestingUser['@id'] : formTicket.requestingUser : null;
    formTicket.affectedUser = formTicket.affectedUser ? typeof formTicket.project === 'object' ? formTicket.affectedUser['@id'] : formTicket.affectedUser : null;
  }

  delete (formTicket.status)
  delete (formTicket.ticketStatus)
  delete (formTicket.daysOpen)
  delete (formTicket.comments)
  delete (formTicket.ticketStatusHistories)
  if (formTicket.ticketDocuments.length > 0) {
    formTicket.ticketDocuments = formTicket.ticketDocuments.map((doc) => {
      return doc['@id'] ? doc['@id'] : doc
    })
  }
  TicketService.updateTicket(formTicket, ticket.value.id).then((res) => {
    if (res && (res.status === 200 || res.status === 201)) {
      // notification.addNotification("Datos guardados correctamente","success",10000)
      ElNotification({
        title: 'Ticket actualizado',
        message: 'Ticket ' + res.data.tag + '-' + res.data.id + ' actualizado con éxito',
        type: 'success',
        duration: 10000
      })
      router.go(-1)
    } else {
      ElNotification({
        title: 'Error',
        message: res.data.detail,
        type: 'error'
      })
    }

  }).catch((e) => {
    ElNotification({
      title: 'Error',
      message: e.response.data.detail,
      type: 'error'
    }).finally(() => {
      isSubmitting.value = false;
    })

  })
}


</script>

<style scoped>

</style>
